import { Popup } from 'components/Popup/Popup';
import './SubscriptionPopup.scss';
import { Button } from 'components/Button/Button';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useState } from 'react';
import { Tile } from 'components/Tile/Tile';
import { PaymentService } from 'api/services/payment.service';

interface ISubscriptionPopup {
	open: boolean;
	onClose: () => void;
}
const PlansTile = ({
	title,
	selectedPlan,
	selectedSubscription,
	handlePlanChange,
	isDefault = false,
}: {
	title: string;
	isSelected?: boolean;
	selectedPlan: string;
	selectedSubscription: string;
	handlePlanChange: (title: string) => void;
	isDefault?: boolean;
}) => {
	return (
		<div
			className={`subscription-plan-popup-component-content-tiles-tile ${
				isDefault
					? 'selected_plan'
					: title.includes(selectedSubscription) && 'current_plan'
			}`}
			onClick={() => handlePlanChange(title)}
		>
			<h3>{title}</h3>
			{isDefault && <Tile color='dark-green' text='Current Plan' />}
			<div className='subscription-plan-popup-component-content-tiles-tile-content'>
				<div className='subscription-plan-popup-component-content-tiles-tile-content-price'>
					<span>$7</span>
					<p>{selectedPlan === 'monthly' ? '/Month' : '/Year'}</p>
				</div>
			</div>
		</div>
	);
};

/**
 * Subscription Popup component.
 *
 * @param {boolean} open - The state of the popup.
 * @param {() => void} onClose - The function to close the popup.
 * @returns {JSX.Element} A JSX Element.
 */
const SubscriptionPopup = ({ open, onClose }: ISubscriptionPopup) => {
	const [selectedPlan, setSelectedPlan] = useState('monthly');
	const [selectedSubscription, setSelectedSubscription] = useState('basic');

	/**
	 * Handles the change event when a new plan is selected.
	 *
	 * @param {React.MouseEvent<HTMLElement>} event - The click event.
	 * @param {string} newAlignment - The new alignment value.
	 * @returns {void}
	 */
	const handleChange = (
		event: React.MouseEvent<HTMLElement>,
		newAlignment: string
	): void => {
		event.preventDefault();
		setSelectedPlan(newAlignment);
	};

	/**
	 * Handles the change of the selected subscription.
	 *
	 * @param {string} title - The title of the selected subscription.
	 * @returns {void}
	 */
	const handleSubscriptionChange = (title: string) => {
		setSelectedSubscription(title);
	};

	/**
	 * Creates a payment for Eazyops Subscription.
	 * @returns {Promise<any>} A promise that resolves with the payment URL or rejects with an error.
	 */
	const createPayment = () => {
		PaymentService.createPayment(7, 'Eazyops Subscription')
			.then((res) => {
				window.location.href = res.data.url;
			})
			.catch((err) => {
				return err;
			});
	};

	return (
		<Popup
			data_cyid='cy-subscription-plan-connection'
			open={open}
			onClose={onClose}
			withButtons={false}
			className='subscription-plan-popup'
			showCloseButton={false}
		>
			<div className='subscription-plan-popup-component'>
				<div className='subscription-plan-popup-component-header'>
					<h1>Subscription Plan</h1>
					<p>Upgrade to access costing, savings and actions.</p>
					<div className='subscription-plan-popup-component-header-buttons'>
						<ToggleButtonGroup
							color='primary'
							value={selectedPlan}
							exclusive
							onChange={handleChange}
							aria-label='Platform'
						>
							<ToggleButton value='monthly'>Monthly</ToggleButton>
							{/* <ToggleButton value='annual'>Annual</ToggleButton> */}
						</ToggleButtonGroup>
					</div>
				</div>
				<div className='subscription-plan-popup-component-content'>
					<div className='subscription-plan-popup-component-content-tiles'>
						<PlansTile
							title='Basic'
							isSelected={selectedSubscription === 'basic'}
							selectedPlan={selectedPlan}
							selectedSubscription={selectedSubscription}
							handlePlanChange={handleSubscriptionChange}
							isDefault
						/>
						{/* <PlansTile
							title='Standard'
							isSelected={selectedSubscription === 'standard'}
							selectedPlan={selectedPlan}
							selectedSubscription={selectedSubscription}
							handlePlanChange={handleSubscriptionChange}
						/>
						<PlansTile
							title='Premium'
							isSelected={selectedSubscription === 'premium'}
							selectedPlan={selectedPlan}
							selectedSubscription={selectedSubscription}
							handlePlanChange={handleSubscriptionChange}
						/> */}
					</div>
					<p>100% secure payment method</p>
					<div className='subscription-plan-popup-component-content-footer'>
						<Button
							text='Upgrade Now'
							className='subscription-plan-popup-component-content-footer-upgrade'
							data_cyid='cy-subscription-plan-upgrade'
							onClick={(ev) => {
								ev.stopPropagation();
								createPayment();
							}}
						/>
						<Button
							text='Cancel'
							className='subscription-plan-popup-component-content-footer-cancel'
							data_cyid='cy-subscription-plan-cancel'
							onClick={(ev) => {
								ev.stopPropagation();
								onClose();
							}}
						/>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default SubscriptionPopup;
