import './ActionDetails.scss';
import { Divider, Drawer, IconButton } from '@mui/material';
import { ReactComponent as PlusIcon } from 'assets/svgs/plus.svg';
import {
	IDiscoveryProject,
	INormalizedSaving,
	IVMResource,
} from 'common/interfaces';
import {
	classNames,
	formatDateTime,
	getCostValue,
	showApplyResourceActionNotification,
	toFixed,
} from 'common/helpers';
import { Button } from 'components/Button/Button';
import { ProjectionsService } from 'api/services/projections.service';
import { useNotifications } from 'components/Notifications/useNotifications';
import { useState } from 'react';
import { ReactComponent as ScaleComputeIcon } from 'assets/svgs/scale-compute.svg';
import { ReactComponent as ComputeIcon } from 'assets/svgs/compute.svg';
import { Tile } from 'components/Tile/Tile';
import { ComputeProgressBars } from 'components/ComputeProgressBars/ComputeProgressBars';
import { Popup } from 'components/Popup/Popup';
import { ConfirmPopupContent } from 'components/Popup/ConfirmPopupContent/ConfirmPopupContent';
import { LoadingProgress } from 'components/LoadingProgress/LoadingProgress';
import { useNavigate, useParams } from 'react-router';
import { DateDropdown } from 'components/DateDropdown/DateDropdown';
import { ReactComponent as ArrowLowIcon } from 'assets/svgs/arrow-low.svg';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'store/app/app-selectors';
import SubscriptionPopup from 'features/Payment/SubscriptionPopup/SubscriptionPopup';
import { IntegrationInstructionsOutlined } from '@mui/icons-material';
import SnippetBoard from 'components/SnippetBoard/SnippetBoard';

const session = sessionStorage.getItem('session');
const session_data = session ? JSON.parse(session) : null;

const computeResourceLabels = (
	<>
		<div className='action-details-component-info-item-li'>
			<span className='label'>VCPU, Capacity:</span>
		</div>
		<div className='action-details-component-info-item-li'>
			<span className='label'>VMem, Capacity:</span>
		</div>
		<div className='action-details-component-info-item-li'>
			<span className='label'>Type:</span>
		</div>
	</>
);

function ComputeResourceImpact({
	resource,
	state,
	saving,
}: {
	resource: IVMResource;
	state: 'before' | 'after';
	saving: INormalizedSaving;
}) {
	return (
		<ul
			data-cyid='cy-actions-compute-resource-details'
			className={classNames(state)}
		>
			<li className='action-details-component-info-item-li list-item'>
				{state === 'before'
					? `${toFixed(resource.normalizedObject.cpu_size, 2)} Ghz`
					: saving?.detail?.cpu_after
						? `${saving?.detail?.cpu_after} Ghz`
						: '\u00A0'}
			</li>
			<li className='action-details-component-info-item-li list-item'>
				{state === 'before'
					? `${toFixed(resource.normalizedObject.memory_size, 2)} GB`
					: saving.detail.memory_after
						? `${saving.detail.memory_after} GB`
						: '\u00A0'}
			</li>
			<li className='action-details-component-info-item-li list-item'>
				{state === 'before'
					? saving?.detail.type_before
					: saving.detail.type_after
						? saving.detail.type_after
						: '\u00A0'}
			</li>
		</ul>
	);
}

function ComputeCostImpact({ saving }: { saving: INormalizedSaving }) {
	return (
		<>
			<div
				data-cyid='cy-compute-cost-details'
				className='action-details-component-info-item-block titles'
			>
				<h3>Cost Impact</h3>
				<div className='action-details-component-info-item-li'>Total Cost:</div>
				<div className='action-details-component-info-item-li'>
					Total Saving:
				</div>
			</div>
			<div
				data-cyid='cy-compute-cost-current-details'
				className='action-details-component-info-item-block current'
			>
				<div className='action-details-component-info-item-block-title'>
					Current State
				</div>
				<div className='action-details-component-info-item-li list-item'>
					{getCostValue(saving?.detail.cost_before)}
				</div>
			</div>

			<div
				data-cyid='cy-vm-cost-after-details'
				className='action-details-component-info-item-block after'
			>
				<div className='action-details-component-info-item-block-title'>
					After Recommended Action
				</div>
				<div className='action-details-component-info-item-li list-item'>
					{getCostValue(saving?.detail.cost_after)}
				</div>
				<div className='action-details-component-info-item-li list-item'>
					<span className='diff'>
						<ArrowLowIcon className='icon' />
						{getCostValue(
							saving?.detail.cost_before - saving?.detail.cost_after
						)}
					</span>
				</div>
			</div>
		</>
	);
}

function KeyActions({ saving }: { saving: INormalizedSaving }) {
	return (
		<>
			<h3>Key actions</h3>
			{saving?.detail.action === 'delete' && (
				<div className='action-details-component-info-item-li'>
					<span className='label'>Non-Reversible:</span>
					<span className='value'>VM delete action is non disruptive.</span>
				</div>
			)}
			{saving?.detail.action === 'scaling' && (
				<div className='action-details-component-info-item-li'>
					<span className='label'>Reversible:</span>
					<span className='value'>VM scale action is disruptive.</span>
				</div>
			)}
		</>
	);
}

function ResourceDetails({
	resourceType,
	resource,
	account,
}: {
	resourceType: 'Compute' | '';
	resource: IVMResource;
	account?: IDiscoveryProject;
}) {
	return (
		<>
			<div
				data-cyid='cy-resource-action-details'
				className='action-details-component-info-item-li resource-name'
			>
				<span className='label'>{resourceType} Name:</span>
				<Tile color='blue' text={resource.name} />
			</div>
			<div
				data-cyid='cy-resource-action-details-info'
				className='action-details-component-info-item-li'
			>
				<span className='label'>{resourceType} Account:</span>
				<span className='value account-name'>{account?.name}</span>
			</div>
			<div
				data-cyid='cy-resource-action-details-info-item'
				className='action-details-component-info-item-li'
			>
				<span className='label'>{resourceType} ID:</span>
				<span className='value'>{resource.id}</span>
			</div>
			<div className='action-details-component-info-item-li'>
				<span className='label'>{resourceType} Region:</span>
				<span className='value'>{resource.normalizedObject.zone}</span>
			</div>
		</>
	);
}

interface ActionsDetailsProps {
	open: boolean;
	onClose: () => void;
	saving: INormalizedSaving;
	resource: IVMResource;
	account?: IDiscoveryProject;
}

function VMActionsDetails({
	open,
	onClose,
	saving,
	resource,
	account,
}: ActionsDetailsProps) {
	const notifications = useNotifications();
	const selectedUser = useSelector(selectCurrentUser);
	const { discoveryId, accountId } = useParams();
	const navigate = useNavigate();
	const resourceType = 'Compute';

	const [isApplyLoading, setIsApplyLoading] = useState(false);
	const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);
	const [isOpen, setOpen] = useState(false);
	const [isSnippetOpen, setSnippetOpen] = useState(false);

	/**
	 * Handles the scheduling of a projection.
	 * @param {string | null} date - The date to schedule the projection.
	 */
	const onSchedule = (date: string | null) => {
		// TO DO changes for projection_id here (design ?)
		if (date) {
			ProjectionsService.applyProjection(
				[saving.projection_id],
				date?.slice(0, 23)
			).catch((error) => console.log({ error }));
		}
	};

	/**
	 * Handles the apply action for the VMActionDetails component.
	 * Sets the apply loading state, applies the projection, shows a notification,
	 * and navigates to the appropriate page based on the action type.
	 * @returns {void}
	 */
	const onApply = () => {
		setIsApplyLoading(true);
		ProjectionsService.applyProjection([saving.projection_id])
			.then(() => {
				setIsApplyLoading(false);
				showApplyResourceActionNotification(notifications, saving, resource);
				if (saving?.detail.action === 'scaling') {
					navigate(
						`/discovery/${discoveryId}/account/${accountId}/actions/compute/scale/resource/${resource.id}`
					);
				} else {
					navigate(
						`/discovery/${discoveryId}/account/${accountId}/actions/compute/delete/list`
					);
				}
				onClose();
			})
			.catch(() => {
				setIsApplyLoading(false);
			});
	};

	/**
	 * Executes the action when triggered.
	 * If the selected user has a renewed_on property, it sets the open state to false and opens the confirm popup.
	 * Otherwise, it sets the open state to true.
	 */
	const onExecute = () => {
		if (selectedUser?.renewed_on) {
			setOpen(false);
			setIsConfirmPopupOpen(true);
		} else {
			setOpen(true);
		}
	};

	return (
		<Drawer
			anchor='right'
			open={open}
			onClose={onClose}
			onClick={(e) => e.stopPropagation()}
			data-cyid='cy-action-details-drawer'
		>
			<div className='action-details-component'>
				<h2>
					<PlusIcon />
					Recommended Action Details
				</h2>
				<div className='action-details-component-controls'>
					<div className='action-details-component-controls-item name'>
						Recommended Action:{' '}
						<div className='action-details-component-controls-item name-detail'>
							{saving?.detail.action} {resourceType}
							<div className='action-details-component-controls-item icon'>
								<ScaleComputeIcon />
							</div>
						</div>
					</div>
					<div className='action-details-component-controls-item buttons'>
						<div className='action-details-component-controls-item-cost'>
							<div>
								{getCostValue(
									saving?.detail.cost_before - saving?.detail.cost_after
								)}
							</div>
							<div className='saving'>Saving</div>
						</div>
						<Divider
							sx={{
								borderColor: '#CBD5E0',
								width: '2px'
							}}
							flexItem
							orientation='vertical'
						/>
						<div className='action-details-component-controls-item-actions'>
							{!session_data &&
								<>
									<Button
										small
										className='blue'
										text='Execute Action Now'
										onClick={onExecute}
										data_cyid='cy-execute-action-button'
									/>
									<DateDropdown onDateSelected={onSchedule} title='Execute Later:' placeholder='Schedule action' />
								</>
							}
							<IconButton
								className='hollow-green-button'
								onClick={() => {
									setSnippetOpen(true);
								}}
								title=' Instructions to execute the action manually'

							>
								<IntegrationInstructionsOutlined sx={{
									height: '26px',
									width: '26px'
								}} />
							</IconButton>
						</div>
					</div>
				</div>
				<div className='action-details-component-info'>
					<div className='action-details-component-info-top'>
						<div className='action-details-component-info-item details left'>
							<h3 className='resource-title'>
								<ComputeIcon />
								{resourceType} details
							</h3>
							<ComputeProgressBars
								resource={resource as IVMResource}
								className='action-details-component-info-item-li'
								saving={saving}
							/>
							<ResourceDetails
								resourceType={resourceType}
								resource={resource}
								account={account}
							/>
							<div className='action-details-component-info-item-li'>
								{
									<>
										<span className='label'>Uptime:</span>
										<span className='value'>
											{formatDateTime(
												(resource as IVMResource).normalizedObject
													.last_start_timestamp
											)}
										</span>
									</>
								}
							</div>
						</div>
						<div className='action-details-component-info-item right'>
							<div className='action-details-component-info-item-block titles'>
								<h3>Resource Impact</h3>
								{computeResourceLabels}
							</div>
							<div className='action-details-component-info-item-block current'>
								<div className='action-details-component-info-item-block-title'>
									Current State
								</div>
								<ComputeResourceImpact
									resource={resource as IVMResource}
									state='before'
									saving={saving}
								/>
							</div>
							<div className='action-details-component-info-item-block after'>
								<div className='action-details-component-info-item-block-title'>
									After Recommended Action
								</div>
								<ComputeResourceImpact
									resource={resource as IVMResource}
									state='after'
									saving={saving}
								/>
							</div>
						</div>
					</div>
					<div className='action-details-component-info-bottom'>
						<div className='action-details-component-info-item left'>
							<KeyActions saving={saving} />
						</div>
						<div className='action-details-component-info-item right'>
							<ComputeCostImpact saving={saving} />
						</div>
					</div>
				</div>
			</div>
			{isOpen && (
				<SubscriptionPopup open={isOpen} onClose={() => setOpen(!isOpen)} />
			)}
			{isConfirmPopupOpen && (
				<Popup
					open={true}
					onClose={() => setIsConfirmPopupOpen(false)}
					submitButtonText='Yes'
					cancelButtonText='No'
					onSubmit={onApply}
					className='confirm-resource-action-popup'
					data_cyid='cy-popup-vm-action-details-modal'
				>
					<ConfirmPopupContent>
						<>
							<p>You're about to perform the following actions.</p>
							<Tile
								color='red'
								text={`${saving.detail.action} 1 ${resourceType}`}
								className='action-details-confirm-tile'
							/>
							<p>Are you sure you want to proceed with these changes?</p>
							{isApplyLoading && (
								<LoadingProgress text='Please be patient while we apply the recommended action(s)' />
							)}
						</>
					</ConfirmPopupContent>
				</Popup>
			)}
			{
				isSnippetOpen && (
					<SnippetBoard resourceType='Compute' snippets={{
						yaml: saving?.yaml as unknown as { command: string },
						cli: saving?.cli as unknown as { command: string },
						teraform: saving?.teraform as unknown as { command: string }
					}} open={isSnippetOpen} handleClose={() => setSnippetOpen(false)} />
				)
			}
		</Drawer>
	);
}

export { VMActionsDetails };
