import { client } from 'api/client';
import {
    IPaginatedResponse,
    IResourceLog,
    ResourceActionType,
    ResourceType,
    ISnapshots,
    IIPs,
    IPodDetails,
    IGKENodes,
    INamespaces,
    IGKEResourceMetrics,
} from 'common/interfaces';

export class ResourcesService {
    static path = 'api/resource';

    // Set Authorization header
    private static setAuthorizationHeader() {
        const session = sessionStorage.getItem('session');
        const session_data = session ? JSON.parse(session) : null;
        if (session_data) {
            const { token } = session_data;
            if (token) {
                client.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            }
        }
    }

    private static getResourceTypeParam(resourceType: ResourceType | ''): string {
        const typeMap: Record<ResourceType | '', string> = {
            [ResourceType.COMPUTE]: 'instance',
            [ResourceType.VOLUME]: 'disk',
            [ResourceType.DATABASE]: 'database',
            [ResourceType.LOGGING]: 'log',
            [ResourceType.GKE]: 'cluster',
            '': '',
        };
        return typeMap[resourceType] || '';
    }

    static getResourceDetails<T>(resourceId: string) {
        this.setAuthorizationHeader();
        return client.get<T>(`${this.path}/${resourceId}`);
    }

    static getResourceMonitoring(
        resourceId: string,
        startTime: string,
        type: 'iops' | 'usage' | 'db_cpu_utilization' | 'db_disk_write_ops' | 'db_disk_read_ops',
        endTime?: string
    ) {
        this.setAuthorizationHeader();
        return client.get(`${this.path}/monitoring/${resourceId}`, {
            params: { type, start_time: startTime, end_time: endTime },
        });
    }

    static getProjectResources<T>(
        projectId: string,
        resourceType: ResourceType | string[] | '',
        page: number,
        size: number,
        actionCategory: ResourceActionType | null,
        search?: string | null,
        sort_by_savings?: 'asc' | 'desc' | null
    ) {
        this.setAuthorizationHeader();
        return client.get<IPaginatedResponse<T>>(
            `${this.path}/${projectId}/list`,
            {
                params: {
                    type: Array.isArray(resourceType) ? resourceType.join(',') : this.getResourceTypeParam(resourceType),
                    page,
                    size,
                    action_category: actionCategory,
                    ...(search && { search }),
                    ...(sort_by_savings && { sort_by_savings }),
                },
            }
        );
    }

    static getSnapshots(
        gcpProject: string,
        resourceType: string,
        connectorId: string
    ) {
        this.setAuthorizationHeader();
        return client.get<ISnapshots[] | IIPs[]>(
            `${this.path}/${connectorId}/list_snapeshots`,
            {
                params: {
                    gcp_project: gcpProject,
                    type: resourceType,
                    days: 90,
                },
            }
        );
    }

    static getResourceLogs(resourceId: string, page: number, size: number) {
        this.setAuthorizationHeader();
        return client.get<IPaginatedResponse<IResourceLog>>(
            `api/log/resource/${resourceId}`,
            {
                params: { page, size },
            }
        );
    }

    static getPods(clusterName: string, discoveryId: string) {
        this.setAuthorizationHeader();
        return client.get<{ [key: string]: IPodDetails[] }>(
            `${this.path}/${clusterName}/list_pods`,
            {
                params: {
                    discovery_id: discoveryId,
                    cluster_name: clusterName,
                    top: 5,
                },
            }
        );
    }

    static getNodes(clusterName: string, discoveryId: string) {
        this.setAuthorizationHeader();
        return client.get<{ [key: string]: IGKENodes[] }>(
            `${this.path}/${clusterName}/list_nodes`,
            {
                params: {
                    discovery_id: discoveryId,
                    cluster_name: clusterName,
                    top: 5,
                },
            }
        );
    }

    static getNamespaces(clusterName: string, discoveryId: string) {
        this.setAuthorizationHeader();
        return client.get<{ [key: string]: INamespaces[] }>(
            `${this.path}/${clusterName}/list_namespaces`,
            {
                params: {
                    discovery_id: discoveryId,
                    cluster_name: clusterName,
                    top: 5,
                },
            }
        );
    }

    static getClusterMetrics(clusterName: string, discoveryId: string) {
        this.setAuthorizationHeader();
        return client.get<IGKEResourceMetrics | null>(`${clusterName}/stats`, {
            params: {
                discovery_id: discoveryId,
                cluster_name: clusterName,
            },
        });
    }

    static getAppliedTagsList(type: string, id: string) {
        this.setAuthorizationHeader();
        return client.get(`api/resource/${type}/${id}/list_labels`);
    }

    static applyTags(projectId: string, requestBody: any) {
        this.setAuthorizationHeader();
        return client.post(`api/resource/${projectId}/labels`, requestBody);
    }
}
