import { toFixed } from 'common/helpers';
import './ProgressBar.scss';

interface ProgressBarProps {
    max: number;
    value: number | null;
    color: string;
    title: string
    percentageValue: number | null;
}

function ProgressBar({ color, max, value, title, percentageValue }: ProgressBarProps) {
    let calculatedPercentage = value ? (value / max) * 100 : null;
    if (calculatedPercentage) {
        if (!isFinite(calculatedPercentage)) {
            calculatedPercentage = 0;
        } else {
            calculatedPercentage = toFixed(calculatedPercentage, 2);
        }
    } else calculatedPercentage = null;

    const displayPercentage = percentageValue !== null ? percentageValue : calculatedPercentage;

    return (
        <div data-cyid='cy-progress-bar-component' className='progress-bar-component'>
            <div className='progress-bar'>
                <div
                    className='progress-bar-value'
                    style={{ backgroundColor: color, width: displayPercentage + '%' }}
                    title={`${displayPercentage}%`}
                ></div>
            </div>
            <span className='progress-bar-component-title'>{title} ({displayPercentage}%)</span>
        </div>
    );
}

export { ProgressBar }