import { useEffect, useMemo, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom';
import { ResourcesService } from 'api/services/resources.service';
import { formatDate, getMonthsOld, } from 'common/helpers';
import { IDiscoveryOverview, IDiscoveryProject, IRDSSnapshots, ISnapshots } from 'common/interfaces';
import { NotificationSeverity } from 'components/Notifications/Notification/Notification';
import { useNotifications } from 'components/Notifications/useNotifications';
import { PageHeader } from 'components/PageHeader/PageHeader';
import { ITableColumn, Table } from 'components/Table/Table';
import { DynamicTabs } from 'components/Tabs/Tabs';
import { Tile } from 'components/Tile/Tile';
import '../AccountResourcesList.scss';
import { useDispatch } from 'react-redux';
import { setBreadCrumbsData } from 'store/discovery/discovery';
import { AccountChildrenRoutes, CoreRoutes } from 'routing/routing-paths';
import { ApplyProjections } from 'components/ApplyProjections/ApplyProjections';
import useDiscoveryTooltipContent from 'hooks/useDiscoveryTooltipContent';
import { useSelector } from 'react-redux';
import { selectSessionData } from 'store/discovery/discovery-selectors';

const session = sessionStorage.getItem('session');
const session_data = session ? JSON.parse(session) : null;

const ResourceSnapshotsTable = () => {
    const {
        account,
        discovery,
    }: { account: IDiscoveryProject; discovery: IDiscoveryOverview } =
        useOutletContext();
    const [snapshots, setSnapshotsData] = useState<ISnapshots[] | IRDSSnapshots[] | null>(null);
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [resourceType, setResourceType] = useState<string>('disk');
    const [page, setPage] = useState(0);

    const onPageChange = (page: number) => setPage(page);
    const notification = useNotifications();
    const { discoveryId } = useParams();

    useEffect(() => {
        setSnapshotsData(null);
        if (account) {
            const connector_id = localStorage.getItem('connector_id');
            setSnapshotsData(null);
            ResourcesService.getSnapshots(
                account?.name,
                resourceType,
                connector_id as string
            )
                .then(({ data }) => {
                    setSnapshotsData(
                        data?.map((item: any) => {
                            return { id: String(item?.id), age: getMonthsOld(resourceType === 'disk' ? item.creation_timestamp : item.startTime), ...item };
                        })
                    );
                })
                .catch((error) => {
                    setSnapshotsData([]);
                    notification.open('Something went wrong while fetching snapshots', NotificationSeverity.ERROR)
                    console.log({ error });
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resourceType])

    const snapshotsColumn: ITableColumn<ISnapshots>[] = [
        {
            name: 'Name',
            element: (resource: ISnapshots) => (
                <div className='logs-resource-table-cell-name resource-table-cell-name'>
                    {resource.name}
                </div>
            ),
            minWidth: '200px',
            sortable: true,
            sortKey: 'name'
        },
        {
            name: 'Size',
            element: (resource: ISnapshots) => (
                <div className='logs-resource-table-cell-type'>
                    {resource?.disk_size_gb?.toFixed(3) || 0} GB
                </div>
            ),
            minWidth: '100px',
            className: 'blue',
            sortable: true,
            sortKey: 'disk_size_gb'
        },
        {
            name: 'Cost',
            element: (resource: ISnapshots) => (
                <div className='logs-resource-table-cell-type blue'>
                    $ {resource?.cost?.toFixed(3) || 0}{' '}
                </div>
            ),
            minWidth: '100px',
            className: 'blue',
            sortable: true,
            sortKey: 'cost'
        },
        {
            name: 'Snapshot Age',
            element: (resource: ISnapshots) => (
                <div className='logs-resource-table-cell-type'>
                    {resource.age}
                </div>
            ),
            minWidth: '100px',
            sortable: true,
            sortKey: 'creation_timestamp'
        },
        {
            name: 'Created On',
            element: (resource: ISnapshots) => (
                <div className='logs-resource-table-cell-type'>
                    {formatDate(resource.creation_timestamp)}
                </div>
            ),
            minWidth: '100px',
            sortable: true,
            sortKey: 'creation_timestamp'
        },
    ];

    const rdsSnapshotsColumn: ITableColumn<IRDSSnapshots>[] = [
        {
            name: 'Name',
            element: (resource: IRDSSnapshots) => (
                <div className='logs-resource-table-cell-name resource-table-cell-name'>
                    {resource.instance}
                </div>
            ),
            minWidth: '200px',
            sortable: true,
            sortKey: 'name'
        },
        {
            name: 'Kind',
            element: (resource: IRDSSnapshots) => (
                <div className='logs-resource-table-cell-type'>
                    {resource?.kind}
                </div>
            ),
            minWidth: '100px',
            className: 'blue',
            sortable: true,
            sortKey: 'kind'
        },
        {
            name: 'Type',
            element: (resource: IRDSSnapshots) => (
                <div className='logs-resource-table-cell-type'>
                    {resource?.type}
                </div>
            ),
            minWidth: '100px',
            className: 'blue',
            sortable: true,
            sortKey: 'type'
        },
        {
            name: 'Cost',
            element: (resource: IRDSSnapshots) => (
                <div className='logs-resource-table-cell-type blue'>
                    $ {resource?.cost?.toFixed(3) || 0}{' '}
                </div>
            ),
            minWidth: '100px',
            className: 'blue',
            sortable: true,
            sortKey: 'cost'
        },
        {
            name: 'Snapshot Age',
            element: (resource: IRDSSnapshots) => (
                <div className='logs-resource-table-cell-type'>
                    {resource.age}
                </div>
            ),
            minWidth: '100px',
            sortable: true,
            sortKey: 'creation_timestamp'
        },
        {
            name: 'Created On',
            element: (resource: IRDSSnapshots) => (
                <div className='logs-resource-table-cell-type'>
                    {formatDate(resource.startTime)}
                </div>
            ),
            minWidth: '100px',
            sortable: true,
            sortKey: 'creation_timestamp'
        },
    ];

    const snapshotsSum = useMemo(() => {
        return snapshots?.reduce((sum, disk) => (sum + disk.cost), 0);
    }, [snapshots])


    const { getTooltipContent } = useDiscoveryTooltipContent({ account, discovery });


    const dispatch = useDispatch();
    const session_connector = useSelector(selectSessionData)
    const isSingleAccount = session_data && session_connector[(session_data?.tenant_id) as string]?.isSingleAccount
    useEffect(() => {
        dispatch(setBreadCrumbsData([
            ...(!isSingleAccount
                ? [{
                    to: `${CoreRoutes.DISCOVERY_PAGE}/${discoveryId}`,
                    label: 'Accounts',
                }]
                : []),
            {
                to: `${CoreRoutes.DISCOVERY_PAGE}/${discovery?.id}/account/${account?.id}/${AccountChildrenRoutes.OVERVIEW}`,
                label: `${account?.name}`,
            },
            {
                to: '',
                label: 'Snapshots',
            },
        ]));
    }
        , [account?.id, account?.name, discovery?.id, discoveryId]);

    return (
        <div className='snapshots-component' >
            <PageHeader
                title={account?.name}
                label='Account'

                lastSync={account?.last_synced_at}
                tooltipContent={getTooltipContent()}
            />
            <ApplyProjections
                savingPercentage={account?.savings_percentage}
                potentialCost={account?.potential_cost}
                actualCost={account?.actual_cost}
                className='account-overview-component-apply-projections'
                showSavingSelection={false}
                isAccountsOverview={false}
                account={account}
                discovery={discovery}
                data_cyid='cy-account-overview-projections'
            />
            <DynamicTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab}
                tabs={[{
                    label: 'Volumes',
                    onClick: () => setResourceType('disk'),
                    content: <div className='snapshots-component__content'>
                        {snapshots?.length ? <div className='snapshots-component__tiles'  >
                            <Tile text={`You can save upto $${snapshotsSum?.toFixed(3)} by removing below ${snapshots?.length} snapshots.`} color='green' />
                            <span><strong>Total: </strong>{snapshots.length}</span>
                        </div> : null}

                        <Table
                            rows={snapshots as ISnapshots[] || []}
                            columns={snapshotsColumn}
                            totalCount={snapshots?.length || 0}
                            placeholderText='We couldn’t find any snapshots'
                            isSkeleton={!snapshots}
                            className='account-resources-list'
                            data_cyid='cy-snapshots-list-table'
                            onPageChange={onPageChange}
                            activePage={page}
                            itemsPerPage={15}
                        />
                    </div>
                },
                {
                    label: 'Database',
                    onClick: () => setResourceType('database'),
                    content: <div className='snapshots-component'>
                        {snapshots?.length ? <div className='snapshots-component__tiles'  >
                            <Tile text={`You can save upto $${snapshotsSum?.toFixed(3)} by removing below ${snapshots?.length} snapshots.`} color='green' />
                            <span><strong>Total: </strong>{snapshots.length}</span>
                        </div> : null}

                        <Table
                            rows={snapshots as IRDSSnapshots[] || []}
                            columns={rdsSnapshotsColumn}
                            totalCount={snapshots?.length || 0}
                            placeholderText='We couldn’t find any snapshots'
                            isSkeleton={!snapshots}
                            className='account-resources-list'
                            data_cyid='cy-snapshots-list-table'
                            onPageChange={onPageChange}
                            activePage={page}
                            itemsPerPage={15}
                        />
                    </div>
                }]}
            />
        </div>

    )
}

export default ResourceSnapshotsTable