import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IBreadcrumb } from 'common/interfaces';

type ISelectedResources = {
  name: string;
  value: boolean;
  defaultKey: boolean;
};

type IRefreshConnectorState = {
  name: string;
  expires_in: number | null;
  connector_id: string;
  updated_at: string | null;
};
interface DiscoveryState {
  selectedResources: ISelectedResources[] | null;
  isRefreshingToken: boolean;
  refreshConnectorState: IRefreshConnectorState | null;
  isSingleProject: boolean;
  isPolicyUpdated: boolean;
  connectionType: string;
  isRediscoveryLoader: boolean;
  clusterReportData: any;
  breadCrumbs: IBreadcrumb[];
  session: Record<string, { connector_id: string, isSingleAccount: string }>
}

const initialState: DiscoveryState = {
  selectedResources: null,
  isRefreshingToken: false,
  refreshConnectorState: null,
  isSingleProject: false,
  isPolicyUpdated: false,
  connectionType: '',
  isRediscoveryLoader: false,
  clusterReportData: null,
  breadCrumbs: [],
  session: {}
};
const discoverySlice = createSlice({
  name: 'discovery',
  initialState,
  reducers: {
    setSelectedResourcesData: (
      state,
      action: PayloadAction<ISelectedResources[]>
    ) => {
      state.selectedResources = action.payload;
    },
    setIsRefreshingToken(state, action: PayloadAction<boolean>) {
      state.isRefreshingToken = action.payload;
    },
    setRefreshConnectorState(
      state,
      action: PayloadAction<IRefreshConnectorState>
    ) {
      state.refreshConnectorState = action.payload;
    },
    setSingleProjectState(state, action: PayloadAction<boolean>) {
      state.isSingleProject = action.payload;
    },

    setPolicyUpdatedState(state, action: PayloadAction<boolean>) {
      state.isPolicyUpdated = action.payload;
    },
    setConnectionTypeState(state, action: PayloadAction<string>) {
      state.connectionType = action.payload;
    },
    setRediscoveryLoader(state, action: PayloadAction<boolean>) {
      state.isRediscoveryLoader = action.payload;
    },
    setClusterReportData(state, action: PayloadAction<any>) {
      state.clusterReportData = action.payload;
    },
    setBreadCrumbsData(state, action: PayloadAction<IBreadcrumb[]>) {
      state.breadCrumbs = action.payload;
    },
    setSessionData(state, action: PayloadAction<Record<string, any>>) {
      state.session = action.payload;
    }
  },
});

export const {
  setSelectedResourcesData,
  setIsRefreshingToken,
  setRefreshConnectorState,
  setSingleProjectState,
  setPolicyUpdatedState,
  setConnectionTypeState, setRediscoveryLoader, setClusterReportData, setBreadCrumbsData, setSessionData

} = discoverySlice.actions;
export const discoveryReducer = discoverySlice.reducer;
