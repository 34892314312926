import { Fragment } from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { IReportProjectInfo } from 'common/interfaces';
import Arrow from 'assets/reportAssets/arrowDown.png';

const styles = StyleSheet.create({
	row: {
		flexDirection: 'row',
		borderBottomColor: '#CBD5E0',
		borderBottomWidth: 1,
		alignItems: 'center',
	},
	rowItem: {
		padding: '10px 10px',
		color: '#000000',
		fontFamily: 'Inter',
		fontWeight: 400,
		fontSize: 10,
		width: 100,
		minWidth: '140px',
		maxWidth: '140px',
	},
	arrowStyles: {
		width: 7,
		marginLeft: 2,
	},
	savingPercentage: { color: '#1EB564' },
	savingRows: {
		backgroundColor: 'rgba(194, 245, 219, 0.5)',
		border: '1px solid #A0EBC7',
		borderBottom: 'none',
		flexDirection: 'row',
		alignItems: 'center',
		gap: '4px',
		minWidth: '105px',
		maxWidth: '105px',
		width: 100,
	},
});

const ProjectTableRow = ({ items }: { items: IReportProjectInfo[] }) => {
	const rows = items.map((item, index) => {
		const monthlySavingPercentage =
			item.monthly_spending > 0
				? parseFloat(
					((item.monthly_saving / item.monthly_spending) * 100).toFixed()
				)
				: 0;
		return (
			<View style={styles.row} key={index}>
				<Text style={{ ...styles.rowItem }}>{item.project_name}</Text>
				<Text style={styles.rowItem}>{item.resources_count}</Text>
				<Text style={styles.rowItem}>${Math.floor(item.monthly_spending)}</Text>
				<View style={[styles.rowItem, styles.savingRows]}>
					<Text> ${Math.floor(item.monthly_saving)}</Text>
					<Image src={Arrow} style={styles.arrowStyles} />
					<Text style={styles.savingPercentage}>
						{monthlySavingPercentage}%
					</Text>
				</View>
			</View>
		);
	});
	return <Fragment>{rows}</Fragment>;
};

export default ProjectTableRow;
