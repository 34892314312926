import { client } from 'api/client';
import { ApplyLoggingProjectionParams, IPriceComparison, IProjectionsResponse, LogsResourcesSeverity } from 'common/interfaces';

export class ProjectionsService {
    static readonly path = '/api/projection';

    static getProjections = (discoveryId: number) => client.get<IProjectionsResponse>(`${ProjectionsService.path}/discovery/${discoveryId}`);

    static createProjectionDiscovery = (projectionId: string, discoveryId: string) => client.post(
        `${ProjectionsService.path}/discovery`,
        {
            p_id: projectionId,
            d_id: discoveryId
        }
    );

    static getPriceComparison = (projectId: string) => client.get<IPriceComparison>(
        `${ProjectionsService.path}/price-comparison`,
        {params: {project_id: projectId}}
    );

    static applyProjection = (
        projection_id: string[],
        schedule?: string,
        retention?: number,
        severity?: LogsResourcesSeverity[],
        type?: string,
        gke_resource_name?:string,
        pod_name?:string
    ) => client.post<{operation_id: number}>(`${ProjectionsService.path}/apply`, {
        schedule,
        projection_id,
        ...(retention && {retention}),
        ...(severity && {severity}),
        ...(type && { type }),
        ...(gke_resource_name && {gke_resource_name}),
        ...(pod_name && {pod_name}),
    });

    static applyLoggingProjection = (body: ApplyLoggingProjectionParams) => client.post<{operation_id: number}>(`${ProjectionsService.path}/apply/logging`, body);
};
