import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { CoreRoutes } from 'routing/routing-paths';
import { getCookie } from 'common/helpers';
import { TOKEN_KEY } from 'common/constants';
import { useAuthSync } from 'hooks/useAuthSync';

interface GuestGuardProps {
	children: ReactElement;
}

const GuestGuard = ({ children }: GuestGuardProps) => {
	const token = getCookie(TOKEN_KEY);
	const URL = getCookie('redirect')

	useAuthSync();
	if (token && getCookie('redirect') && getCookie('redirect')?.includes('tenant_id')) {
		return <Navigate to={URL as string} />;
	} else if (token) {
		return <Navigate to={CoreRoutes.DISCOVERIES} />;
	}
	else
		return children;
};

export default GuestGuard;
