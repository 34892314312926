import './TextInput.scss';
import {
	ChangeEvent,
	ChangeEventHandler,
	FocusEvent,
	FocusEventHandler,
	KeyboardEventHandler,
	useEffect,
	useState,
} from 'react';
import { ValidationMessage } from 'components/ValidationMessage/ValidationMessage';
import { classNames } from 'common/helpers';
import { Validators } from 'common/interfaces';

interface TextInputProps {
	id: string;
	value: number | string | undefined;
	onChange: ChangeEventHandler;
	onBlur?: FocusEventHandler;
	onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
	placeholder?: string;
	validators?: {
		type: Validators;
		value: RegExp | boolean | number;
		errorMessage: string;
	}[];
	onValidationChanged?: (isValid: boolean) => void;
	autofocus?: boolean;
	isGhost?: boolean;
	disabled?: boolean;
	className?: 'small' | 'big' | 'inline' | string;
	type?: 'search' | 'text';
	autocomplete?: 'on' | 'off';
	showValidationMode?: 'change' | 'blur';
	label?: string;
	data_cyid: string;
	maxLength?: number | null;
	apiError?: string | null; // New prop to handle API error
}

function TextInput({
	id,
	value,
	onChange,
	onBlur = () => { },
	onKeyDown = () => { },
	placeholder = 'Type here',
	validators,
	onValidationChanged = () => { },
	autofocus = false,
	isGhost = false,
	disabled = false,
	className = 'big',
	type = 'text',
	autocomplete = 'on',
	showValidationMode = 'change',
	label = '',
	data_cyid = 'cy-text-input-component',
	maxLength,
	apiError = null, // Default value for new prop
}: TextInputProps) {
	const [errors, setErrors] = useState<string[]>([]);
	const [validationShown, setValidationShown] = useState(
		showValidationMode === 'change'
	);

	/**
	 * Handles the change event of the input element.
	 * @param e - The change event object.
	 */
	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		let isValid = true;

		if (validators) {
			const inputValue = e.target.value;
			let errors: string[] = []; // Clear the errors array at the start of each validation cycle

			validators.some((validator) => {
				if (
					(validator.type === Validators.REQUIRED &&
						validator.value &&
						!inputValue.trim()) ||
					(validator.type === Validators.MAX_LENGTH &&
						inputValue.length > (validator.value as number)) ||
					(validator.type === Validators.MIN_LENGTH &&
						inputValue.length < (validator.value as number)) ||
					(validator.type === Validators.REG_EXP &&
						inputValue.trim() &&
						!(validator.value as RegExp).test(inputValue))
				) {
					errors.push(validator.errorMessage);
					return true; // Stop checking further conditions
				}
				return false;
			});

			if (errors.length) {
				isValid = false;
			}

			setErrors(errors);
		}

		changeValidationVisibility('change');
		onChange(e);
		onValidationChanged(isValid);
	};

	/**
	 * Handles the blur event of the input element.
	 * @param {FocusEvent<HTMLInputElement>} e - The blur event object.
	 */
	const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
		changeValidationVisibility('blur');
		onBlur(e);
	};

	/**
	 * Changes the visibility of validation based on the event type.
	 * @param eventType - The type of event ('blur' or 'change').
	 */
	const changeValidationVisibility = (eventType: 'blur' | 'change') => {
		if (!validationShown && showValidationMode === eventType) {
			setValidationShown(true);
		} else if (
			validationShown &&
			showValidationMode === 'blur' &&
			eventType === 'change'
		) {
			setValidationShown(false);
		}
	};

	/**
	 * Effect to handle API error changes.
	 * If the API error is resolved (i.e., it becomes null or an empty string),
	 * clear the existing error message.
	 */
	useEffect(() => {
		if (apiError) {
			setErrors([apiError]);
		} else {
			// Clear the errors if the apiError is null or an empty string
			setErrors([]);
		}
	}, [apiError]);

	const isInvalid = (apiError || (validationShown && !!errors.length)) && !disabled;

	return (
		<div
			data-cyid={data_cyid}
			className={classNames('text-input-component', label && 'with-label')}
		>
			{label && <label>{label}</label>}
			<input
				className={classNames(
					'text-input-component-input',
					className,
					isInvalid && 'invalid',
					isGhost && 'ghost',
					type
				)}
				id={id}
				value={value}
				onChange={handleChange}
				onBlur={handleBlur}
				onKeyDown={onKeyDown}
				placeholder={isGhost ? '' : placeholder}
				autoFocus={autofocus}
				disabled={isGhost || disabled}
				autoComplete={autocomplete}
				maxLength={maxLength || undefined}
			/>
			{isInvalid &&
				errors.map((error) => (
					<ValidationMessage key={error} text={error} shown={true} />
				))}
		</div>
	);
}

export { TextInput };
