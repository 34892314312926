import { ReactElement } from 'react';
import './PageHeader.scss';
import { ReactComponent as AddIcons } from 'assets/svgs/plus.svg';
import { classNames, formatDate } from 'common/helpers';
import { Tile } from 'components/Tile/Tile';
import { Button } from 'components/Button/Button';
import { CustomizedTooltip } from 'components/Tooltip/Tooltip';
import { SyncOutlined } from '@mui/icons-material';
import { ReactComponent as CloudIcon } from 'assets/svgs/googleCloud.svg';

interface PageHeaderProps {
	title?: string;
	leftControls?: ReactElement;
	rightControls?: ReactElement;
	bottomControls?: ReactElement;
	tooltipContent?: ReactElement;
	loading?: boolean;
	label?: string;
	btnLabel?: string;
	onBtnClick?: () => void;
	lastSync?: string | null;
}

function PageHeader({
	title = '',
	leftControls,
	rightControls,
	bottomControls,
	loading = false,
	label,
	btnLabel,
	onBtnClick,
	tooltipContent,
	lastSync,
}: PageHeaderProps) {
	return (
		<div data-cyid='cy-page-header-component' className='page-header-component'>
			<div
				className={`page-header-component-top ${!!btnLabel ? 'with-btn' : ''}`}
			>
				<div className='page-header-component-top__head'>
					<h1 className={classNames(loading && 'loading')}>
						<CloudIcon />
						{title}
						<span className='page-header-label'>
							{label && <Tile text={label} color='blue' />}
							{!!tooltipContent && (
								<CustomizedTooltip
									tooltipContent={tooltipContent}
									placement='bottom-end'
									data_cyid='cy-tooltip-page-header-label'
								/>
							)}
						</span>
					</h1>
					{lastSync && <span className='sync' title='last synced' >{formatDate(lastSync)}
						<SyncOutlined sx={{ height: '15px' }} titleAccess='last synced' />
					</span>}
				</div>
				{(!!leftControls || !!rightControls) && (
					<div
						data-cyid='cy-page-header-component-controls'
						className={classNames(
							'page-header-component-controls',
							(!leftControls || !rightControls) && 'single'
						)}
					>
						{leftControls && (
							<div className='page-header-component-controls-left'>
								{leftControls}
							</div>
						)}
						{rightControls && (
							<div className='page-header-component-controls-right'>
								{rightControls}
							</div>
						)}
					</div>
				)}
				{btnLabel && (
					<>
						<Button
							className='blue'
							icon={<AddIcons />}
							text={btnLabel}
							small
							onClick={onBtnClick}
							data_cyid='cy-page-header-add-button'
						/>
					</>
				)}
			</div>
			{bottomControls}
		</div>
	);
}

export { PageHeader };
