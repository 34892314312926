import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import { ReactElement } from 'react';
import {
	GKEResourceType,
	IBaseResource,
	IDBResource,
	ILoggingResourceBucket,
	INormalizedSaving,
	IResource,
	ISavingItem,
	IVMResource,
	IVolumeResource,
	LogsResourcesSeverity,
	ResourceActionType,
	ResourceType,
	Savings,
} from './interfaces';
import { NotificationSeverity } from 'components/Notifications/Notification/Notification';
import { LOGGING_VOLUME_UNIT_PRICE } from './constants';
import Cookies from 'js-cookie';
import { ReactComponent as ComputeIcon } from 'assets/svgs/policy-engine-compute.svg';
import { ReactComponent as VolumeIcon } from 'assets/svgs/policy-engine-volume.svg';
import { ReactComponent as DBIcon } from 'assets/svgs/policy-engine-database.svg';
import { ReactComponent as GKEIcon } from 'assets/svgs/policy-engine-cluster.svg';
import { ReactComponent as LogIcon } from 'assets/svgs/policy-engine-logs.svg';
import { ReactComponent as PodIcon } from 'assets/svgs/policy-engine-pod.svg';
import { ReactComponent as DeploymentIcon } from 'assets/svgs/policy-engine-deployment.svg';
import { CustomizedTooltip } from 'components/Tooltip/Tooltip';
import { ReactComponent as ComputeBWIcon } from 'assets/svgs//computeMenu.svg';
import { ReactComponent as VolumeBWIcon } from 'assets/svgs/VolumesMenu.svg';
import { ReactComponent as DatabaseBWIcon } from 'assets/svgs/DBMenu.svg';
import { ReactComponent as ClusterBWIcon } from 'assets/svgs/ClusterMenu.svg';
import { ReactComponent as LogBWIcon } from 'assets/svgs/LogMenu.svg';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(relativeTime);

export const classNames = (
	...classes: (string | boolean | undefined | null)[]
) => classes.filter(Boolean).join(' ');

export const getTimeAgo = (inputDate: string) => {
	const date = dayjs.utc(inputDate).local();
	const now = dayjs();
	return date.from(now);
};

export function normalizeResources<
	T extends IVMResource | IVolumeResource | ILoggingResourceBucket | IDBResource
>(resources: T[]): T[] {
	return resources.map((resource) => normalizeResource(resource));
}

export function normalizeResource<T extends IBaseResource>(resource: T): T {
	return {
		...resource,
		normalizedObject: JSON.parse(resource.object),
		normalizedSavings:
			resource?.savings &&
			Object.entries(resource?.savings).reduce((acc: any, [key, value]) => {
				acc[key] = normalizeSavings(value);
				return acc;
			}, {}),
	};
}

export function normalizeSavings(
	savings: Record<Savings, ISavingItem>
): INormalizedSaving[] {
	return Object.entries(savings).map(([key, value]) => {
		const normalizedSaving: INormalizedSaving = {
			...value,
			id: key as Savings,
			name: `${toFixed(value.detail.saving_percentage, 2)}% ${value.detail.policy_name
				}`,
		};

		return normalizedSaving;
	});
}

export const percentCalculation = (a: any, b: any) => {
	let c: any = (parseFloat(a) * parseFloat(b)) / 100;
	return parseFloat(c);
};

const reformate = (resource: any) => {
	const reformated = {
		...resource,
		...JSON.parse(resource.object),
	};
	reformated['actual_cost'] = reformated.savings.high.actual_cost;
	reformated['new_size_gb'] = percentCalculation(
		reformated.size_gb,
		reformated.savings.high.amountPercent
	);
	return reformated;
};

export const reformateResources = (resources: IResource[]) => {
	return resources.map((resource) => reformate(resource));
};

export const formatDateTime = (dateInput: string): string => {
	const date = dayjs.utc(dateInput).local();
	const formattedDate = date.format('DD-MM-YYYY');
	const formattedTime = date.format('hh:mm A');

	return `${formattedDate} at ${formattedTime}`;
};

export const formatDate = (dateInput: string): string => {
	const date = dayjs.utc(dateInput).local();

	return date.format('DD-MM-YYYY');
};

export const toFixed = (value: number, count: number): number => {
	return value && Number(value.toFixed(count));
};

export const gbToTb = (gb: number) => {
	return Number(gb / 1024).toPrecision(4);
};

export const kbToGb = (kb: number) => {
	return Number((kb / 1024) * 1024).toPrecision(4);
};

export const showApplyResourceActionNotification = (
	notifications: {
		open: (text: string, severity: NotificationSeverity) => void;
	},
	saving: INormalizedSaving,
	resource: IBaseResource
) => {
	const action = saving.detail.action;
	if (action === ResourceActionType.SCALING) {
		notifications.open(
			`We have initiated the downsizing process for the specified ${resource?.name}, we will notify you once the operation is completed`,
			NotificationSeverity.SUCCESS
		);
	} else if (action === ResourceActionType.DELETE) {
		notifications.open(
			`the specified resource ${resource?.name} has been removed`,
			NotificationSeverity.SUCCESS
		);
	}
};

export const getCostValue = (cost: number | null): string => {
	return cost === null ? 'N/A' : `$ ${toFixed(cost, 2)}/Month`;
};

export const getHighestResourceSaving = (
	savings: Record<Savings, ISavingItem>
): ISavingItem | null => {
	if (!savings) {
		return null;
	}

	return (
		savings[Savings.AGGRESSIVE] ||
		savings[Savings.MODERATE] ||
		savings[Savings.LOW] ||
		null
	);
};

export function styleStringWithBold(
	text: string,
	boldValues: any[]
): JSX.Element {
	let styledText = text;

	boldValues.forEach((boldValue) => {
		styledText = styledText.replace(
			new RegExp(boldValue, 'g'),
			`<strong>${boldValue}</strong>`
		);
	});

	return <span dangerouslySetInnerHTML={{ __html: styledText }} />;
}

export function capitalizeFirstLetter(string: string) {
	return string ? `${string[0].toLocaleUpperCase() + string.slice(1)}` : '';
}

export function getCurrentWeekDates(): { startDate: string; endDate: string } {
	const currentDate: Date = new Date();
	const currentDayOfWeek: number = currentDate.getDay();
	// Calculate the start date (Sunday) of the current week
	const startDate: Date = new Date(currentDate);
	startDate.setDate(currentDate.getDate() - currentDayOfWeek);

	// Calculate the end date (Saturday) of the current week
	const endDate: Date = new Date(currentDate);
	endDate.setDate(currentDate.getDate() + (6 - currentDayOfWeek));

	return {
		startDate: startDate.toISOString(),
		endDate: endDate.toISOString(),
	};
}

export const getNewLoggingCost = (
	severity: LogsResourcesSeverity[],
	severityDetails: Record<LogsResourcesSeverity, number>,
	currentVolume: number,
	selectedSeverities?: Set<LogsResourcesSeverity> | null,
	retention?: number | null
) => {
	const currentSeveritiesArray = severity;
	const currentSeverities = new Set(currentSeveritiesArray);
	let addedSeveritiesVolume = 0;
	let deletedSeveritiesVolume = 0;

	if (selectedSeverities) {
		addedSeveritiesVolume = Array.from(selectedSeverities).reduce(
			(acc: number, severity: LogsResourcesSeverity) => {
				if (!currentSeverities.has(severity)) {
					acc += severityDetails[severity] || 0;
				}
				return acc;
			},
			0
		);

		deletedSeveritiesVolume = currentSeveritiesArray.reduce(
			(acc: number, severity: LogsResourcesSeverity) => {
				if (!selectedSeverities.has(severity)) {
					acc += severityDetails[severity] || 0;
				}
				return acc;
			},
			0
		);
	}

	let retentionCostImpact = 0;

	if (retention && retention > 30) {
		retentionCostImpact = currentVolume * 0.01;
	}

	const newResourceVolume =
		currentVolume + addedSeveritiesVolume - deletedSeveritiesVolume;

	return getCostValue(
		newResourceVolume * LOGGING_VOLUME_UNIT_PRICE - retentionCostImpact
	);
};

export function getHighLowMid(
	arr: { key: string; value: number | 0 }[],
	obj: { key: string; value: number },
	isHeader?: boolean
): string {
	const values = arr.map((item) => item.value);
	const min = Math.min(...values);
	const max = Math.max(...values);
	const mid = values.reduce((acc, val) => acc + val, 0) - min - max;
	if (obj.value !== 0) {
		if (obj.value === min) {
			return isHeader
				? 'green'
				: 'green';
		} else if (obj.value === max) {
			return !isHeader ? 'red' : '';
		} else if (obj.value.toFixed(4) === mid.toFixed(4)) {
			return !isHeader ? 'yellow' : '';
		}
	}
	return '';
}

export function getWeekDay(date: string): string {
	const weekdays = [
		'Sunday',
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday',
	];
	const newDate = new Date(date);
	const dayOfWeek = newDate.getDay();
	return weekdays[dayOfWeek];
}

export function calculateClusterAge(clusterCreationDate: Date): number {
	const currentDate = new Date();
	const timeDifference = currentDate.getTime() - clusterCreationDate.getTime();
	const ageInDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
	return ageInDays;
}
export const daemonStatusMap: { [key: string]: string } = {
	running: 'Running',
	pending: 'Pending',
	inprogress: 'InProgress',
	failed: 'Failed',
	deleted: 'Deleted',
	updating: 'Updating',
	degraded: 'Degraded',
};

export const workloadConditions: { [key: string]: string } = {
	progressing: 'Progressing',
	available: 'Available',
	replicaFailure: 'Replica Failure',
	paused: 'Paused',
	minimumAvailable: 'Minimum Available',
	rollback: 'Rollback',
};

export const resourceTypes: { [key: string]: string } = {
	volumes: 'Volumes',
	computes: 'Computes',
	logs: 'Logs',
	databases: 'Databases',
	clusters: 'Clusters',
};

export const replicaSetsStatus: { [key: string]: string } = {
	running: 'Running',
	pending: 'Pending',
	failed: 'Failed',
	unknown: 'Unknown',
};

export const nodesStatusMap: { [key: string]: string } = {
	ready: 'Ready',
	notready: 'Not Ready',
};

export const secretTypes: { [key: string]: string } = {
	opaque: 'Opaque',
	tls: 'TLS',
	dockerRegistry: 'Docker registry',
	generic: 'Generic',
};

export const serviceTypes: { [key: string]: string } = {
	clusterip: 'Cluster IP',
	nodeport: 'Node Port',
	loadbalancer: 'Load Balancer',
};

export const policyClaimTypes: { [key: string]: string } = {
	delete: 'Delete',
	retain: 'Retain',
};

export const jobConditions: { [key: string]: string } = {
	complete: 'Complete',
	failed: 'Failed',
	running: 'Running',
	pending: 'Pending',
};
export const persistentVolumeStatusMap: { [key: string]: string } = {
	creating: 'Creating',
	ready: 'Ready',
	bound: 'Bound',
	attached: 'Attached',
	release: 'Released',
	failed: 'Failed',
	deleted: 'Deleted',
	pending: 'Pending',
	available: 'Available',
	reclaiming: 'Reclaiming',
	recyled: 'Recycled',
};
export const persistentVolumeClaimStatusMap: { [key: string]: string } = {
	bound: 'Bound',
	lost: 'Lost',
	failed: 'Failed',
	deleting: 'Deleting',
	provisioning: 'Provisioning',
	pending: 'Pending',
	available: 'Available',
	reclaiming: 'Reclaiming',
	recyled: 'Recycled',
};

export const getGKESearchPlaceholder = (resourceType: GKEResourceType) => {
	switch (resourceType) {
		case GKEResourceType.CLUSTER_ROLES:
			return 'Search cluster roles...';
		case GKEResourceType.CONFIG_MAPS:
			return 'Search config maps...';
		case GKEResourceType.CRB:
			return 'Search cluster role bindings...';
		case GKEResourceType.CRON_JOBS:
			return 'Search cron jobs...';
		case GKEResourceType.DAEMON_SETS:
			return 'Search daemon sets...';
		case GKEResourceType.DEPLOYMENTS:
			return 'Search deplpoyments...';
		case GKEResourceType.ENDPOINTS:
			return 'Search endpoints...';
		case GKEResourceType.EVENTS:
			return 'Search events...';
		case GKEResourceType.HPA:
			return 'Search horizontal pods autoscalers...';
		case GKEResourceType.INGRESSES:
			return 'Search ingresses...';
		case GKEResourceType.JOBS:
			return 'Search jobs...';
		case GKEResourceType.PODS:
			return 'Search pods...';
		case GKEResourceType.NAMESPACE:
			return 'Search namespace...';
		case GKEResourceType.PERSISTENT_VOLUMES:
			return 'Search persistent volumes...';
		case GKEResourceType.PSP:
			return 'Search pod security policies...';
		case GKEResourceType.REPLICA_SETS:
			return 'Search replica sets...';
		case GKEResourceType.STATEFUL_SETS:
			return 'Search stateful sets...';
		case GKEResourceType.ROLES:
			return 'Search roles...';
		case GKEResourceType.ROLE_BINDINGS:
			return 'Search role bindings...';
		case GKEResourceType.SECRECTS:
			return 'Search secrets...';
		case GKEResourceType.SERVICES:
			return 'Search network services...';
		case GKEResourceType.SERVICE_ACCOUNTS:
			return 'Search service accounts...';
		case GKEResourceType.STORAGE_CLASSES:
			return 'Search storage classes...';
		case GKEResourceType.PVC:
			return 'Search persistent volume claims...';
		default:
			return 'Type here...';
	}
};

export function setCookie(name: string, value: string, days: number): void {
	Cookies.set(name, value, { expires: days });
}

export function getCookie(name: string): string | undefined {
	return Cookies.get(name);
}

export function deleteCookie(name: string): void {
	Cookies.remove(name);
}
export const formatMemory = (
	memory: number,
	unit: string | '' | undefined = ''
): string => {
	switch (unit) {
		case 'KB':
			return `${Math.round(memory * 1024)} KB`;
		case 'MB':
			return `${Math.round(memory * 1024 * 1024) / 1024} MB`;
		case undefined:
			return 'Unknown unit';
		default:
			return `${memory} GB`;
	}
};

export const formatFullName = (fullName: string): string => {
	if (fullName) {
		const words = fullName.split('_');
		const capitalizedWords = words.map(
			(word) => word.charAt(0).toUpperCase() + word.slice(1)
		);
		return capitalizedWords.join(' ');
	}
	return '';
};

export function formatTime(date: Date): string {
	const timeOptions: Intl.DateTimeFormatOptions = {
		hour: 'numeric',
		minute: 'numeric',
		hour12: true,
	};
	return new Intl.DateTimeFormat('en-US', timeOptions).format(date);
}

export const failureMessages: Record<string, string> = {
	disk: 'disk discovery failed',
	instance: 'instance discovery failed',
	database: 'database discovery failed',
	log: 'log discovery failed',
	cluster: 'cluster discovery failed',
};
export const labelMapping: Record<string, string> = {
	disk: 'Volume',
	instance: 'Compute',
	database: 'Database',
	cluster: 'Cluster',
	log: 'Log',
	pod: 'Pod',
	deployment: 'Deployment',
	node: 'Nodes',
	disk_snapshots: 'Volume Snapshots',
	disk_snapshot: 'Volume Snapshots',
	static_ip: 'Unused IPs',
	database_snapshots: 'DB Snapshots',
	database_snapshot: 'DB Snapshots',
	artifact_image: 'Artifact Images',
};
export const reverselabelMapping: Record<string, string> = {
	volume: 'disk',
	compute: 'instance',
	database: 'database',
	cluster: 'cluster',
	log: 'log',
	pod: 'pod',
	deployment: 'deployment',
};
export const resourceTypeMapping: Record<string, string> = {
	logging: 'log',
	database: 'database',
	volume: 'disk',
	compute: 'instance',
	GKE: 'cluster',
}
export const successMessages: Record<string, string> = {
	disk: 'disk discovery completed',
	instance: 'instance discovery completed',
	database: 'database discovery completed',
	log: 'log discovery completed',
	cluster: 'cluster discovery completed',
};

export const subscriptionPlans: Record<string, string> = {
	Basic: 'basic',
	Standard: 'standard',
	Premium: 'premium',
};

export const token_expiry_options = [
	{ value: '1', label: '1 hour' },
	{ value: '6', label: '6 hour' },
	{ value: '24', label: '24 hour' },
	{ value: 'unlimited', label: 'No Expiration' },
];
export const link_expiry_options = [
	{ value: '1', label: '24 Hours' },
	{ value: '2', label: '2 Days' },
	{ value: '4', label: '4 Days' },
	{ value: '7', label: '1 Week' },
	{ value: '14', label: '2 Weeks' },
	{ value: '21', label: '3 Weeks' },
	{ value: '30', label: '1 Month' },
];
const oauthConfig = process.env;

export const eazyConnectAuthenticatorURL = `${oauthConfig.REACT_APP_OAUTH_URL}?response_type=${oauthConfig.REACT_APP_OAUTH_RESPONSE_TYPE}&access_type=${oauthConfig.REACT_APP_OAUTH_ACCESS_TYPE}&client_id=${oauthConfig.REACT_APP_OAUTH_CLIENT_ID}&redirect_uri=${oauthConfig.REACT_APP_OAUTH_REDIRECT_URI}&scope=${oauthConfig.REACT_APP_OAUTH_SCOPE}`;
export const eazyConnectAuthenticator = () => {
	window.location.replace(eazyConnectAuthenticatorURL);
};
export const getResourceType = (resourceType: string) => {
	switch (resourceType) {
		case 'compute#disk':
			return ResourceType.VOLUME;
		case 'compute#instance':
			return ResourceType.COMPUTE;
		case 'sql#instance':
			return ResourceType.DATABASE;
		case 'gke#cluster':
			return ResourceType.GKE;
		case 'storage#log':
			return ResourceType.LOGGING;
	}
};

export const monthNames = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec',
];
export function hoursToMilliseconds(hours: number): number {
	const millisecondsInHour: number = 60 * 60 * 1000;
	return hours * millisecondsInHour;
}
export const DurationOptions = [
	{ label: 'Hour', value: 'hour' },
	{ label: 'Day', value: 'day' },
	{ label: 'Week', value: 'week' },
	{ label: 'Month', value: 'month' },
	{ label: 'Year', value: 'year' },
];
export function calculateDateRange(option: string) {
	const endDate: Date = new Date();
	let startDate: Date = new Date();

	switch (option) {
		case 'Today':
			startDate.setHours(0, 0, 0, 0);
			endDate.setHours(23, 59, 59, 999);
			break;
		case 'Last 7 days':
			startDate.setDate(startDate.getDate() - 6); // Start date is 7 days ago
			break;
		case 'Last 14 days':
			startDate.setDate(startDate.getDate() - 13); // Start date is 14 days ago
			break;
		case 'Last 30 days':
			startDate.setDate(startDate.getDate() - 29); // Start date is 30 days ago
			break;
		case 'Last 90 days':
			startDate.setDate(startDate.getDate() - 89); // Start date is 90 days ago
			break;
		default:
			break;
	}
	startDate.setHours(0, 0, 0, 0);
	if (option !== 'Today') {
		endDate.setHours(23, 59, 59, 999);
	}

	return { startDate, endDate };
}

export const randomNumbers = Math.floor(Math.random() * 90) + 10;
export function generateTitle() {
	const words = [
		'compute',
		'storage',
		'networking',
		'bigtable',
		'spanner',
		'pubsub',
		'firestore',
		'functions',
	];
	const randomWords = [];
	for (let i = 0; i < 3; i++) {
		const randomIndex = Math.floor(Math.random() * words.length);
		randomWords.push(words[randomIndex]);
	}
	return `eazy_${randomWords.join('_')}_${randomNumbers}`;
}

export const calculateIntervalMinutes = (key: string, value: number) => {
	let minutes = 0;
	switch (key) {
		case 'hour':
			minutes = value * 60;
			break;
		case 'day':
			minutes = value * 24 * 60;
			break;
		case 'week':
			minutes = value * 7 * 24 * 60;
			break;
		case 'month':
			// Assuming a month is 30 days
			minutes = value * 30 * 24 * 60;
			break;
		case 'year':
			// Assuming a year is 365 days
			minutes = value * 365 * 24 * 60;
			break;
		default:
			break;
	}
	return minutes;
};

export const extractTimeFromMinutes = (minutes: number) => {
	const date = dayjs()
		.add(minutes, 'minute')
		.tz(Intl.DateTimeFormat().resolvedOptions().timeZone);
	return date;
};

export function nextRunCycle(date1: string, date2: string) {
	const diffInMilliseconds = Math.abs(
		new Date(date1).getTime() - new Date(date2).getTime()
	);
	const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
	const days = Math.floor(diffInSeconds / (3600 * 24));
	const hours = Math.floor((diffInSeconds % (3600 * 24)) / 3600);
	const minutes = Math.floor((diffInSeconds % 3600) / 60);
	const seconds = diffInSeconds % 60;

	return [days, hours, minutes, seconds]
		.map((v) => (v < 10 ? '0' + v : v))
		.join(':');
}

export const currTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export function getLocalFormattedTime(timestamp: string): string {
	return new Date(timestamp).toLocaleTimeString([], {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
		hour: '2-digit',
		minute: '2-digit',
	});
}

export const getCurrentUTCTimestamp = (): string => {
	const now = new Date();
	const year = now.getUTCFullYear();
	const month = String(now.getUTCMonth() + 1).padStart(2, '0');
	const day = String(now.getUTCDate()).padStart(2, '0');
	const hours = String(now.getUTCHours()).padStart(2, '0');
	const minutes = String(now.getUTCMinutes()).padStart(2, '0');
	const seconds = String(now.getUTCSeconds()).padStart(2, '0');
	const milliseconds = String(now.getUTCMilliseconds()).padStart(3, '0');

	const microseconds = String(Math.floor(Math.random() * 1000)).padStart(
		3,
		'0'
	);

	return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${microseconds}`;
};

export const iconsMapping: Record<string, JSX.Element> = {
	instance: (
		<CustomizedTooltip
			tooltipContent={<span>Compute</span>}
			children={<ComputeIcon height='30px' width='43px' />}
			placement='top'
			data_cyid='compute-tooltip'
			key={'compute'}
		/>
	),
	disk: (
		<CustomizedTooltip
			tooltipContent={<span>Volume</span>}
			children={
				<VolumeIcon height='30px' width='43px' color='#4285F4' fill='#AECBFA' />
			}
			placement='top'
			data_cyid='volume-tooltip'
			key={'volume'}
		/>
	),
	database: (
		<CustomizedTooltip
			tooltipContent={<span>Database</span>}
			children={<DBIcon height='30px' width='43px' />}
			placement='top'
			data_cyid='database-tooltip'
			key={'database'}
		/>
	),
	cluster: (
		<CustomizedTooltip
			tooltipContent={<span>Cluster</span>}
			children={<GKEIcon height='30px' width='43px' />}
			placement='top'
			data_cyid='gke-tooltip'
			key={'cluster'}
		/>
	),
	log: (
		<CustomizedTooltip
			tooltipContent={<span>Logs</span>}
			children={<LogIcon height='30px' width='43px' />}
			placement='top'
			data_cyid='logs-tooltip'
			key={'logs'}
		/>
	),
	pod: (
		<CustomizedTooltip
			tooltipContent={<span>Pods</span>}
			children={<PodIcon height='30px' width='40px' />}
			placement='top'
			data_cyid='pods-tooltip'
			key={'pods'}
		/>
	),
	deployment: (
		<CustomizedTooltip
			tooltipContent={<span>Deployment</span>}
			children={<DeploymentIcon height='30px' width='43px' />}
			placement='top'
			data_cyid='deployment-tooltip'
			key={'deployment'}
		/>
	),
};

export function getMonthsOld(dateString: string): string {
	const givenDate = new Date(dateString);
	const currentDate = new Date();

	const yearsDifference = currentDate.getFullYear() - givenDate.getFullYear();
	const monthsDifference = currentDate.getMonth() - givenDate.getMonth();

	let totalMonths = yearsDifference * 12 + monthsDifference;

	// Handle edge case where the given date is later in the month than the current date
	if (currentDate.getDate() < givenDate.getDate()) {
		totalMonths -= 1;
	}

	return `${totalMonths} months old`;
}

export const db_specs: Record<string, {
	vCPUs: number;
	Memory: number;
}> = {
	//Enterprise includes following and having e.g.db - custom - 1 - 6656(1vCpu & 6.5Gb)
	'db-g1-small': {
		'vCPUs': 1,
		'Memory': 1.7
	},
	'db-f1-micro': {
		'vCPUs': 1,
		'Memory': 0.62
	},
	// Enterprise Plus
	'db-perf-optimized-N-2': {
		'vCPUs': 2,
		'Memory': 16
	},
	'db-perf-optimized-N-4': {
		'vCPUs': 4,
		'Memory': 32
	},
	'db-perf-optimized-N-8': {
		'vCPUs': 8,
		'Memory': 64
	},
	'db-perf-optimized-N-16': {
		'vCPUs': 16,
		'Memory': 128
	},
	'db-perf-optimized-N-32': {
		'vCPUs': 32,
		'Memory': 256
	},
	'db-perf-optimized-N-48': {
		'vCPUs': 48,
		'Memory': 384
	},
	'db-perf-optimized-N-64': {
		'vCPUs': 64,
		'Memory': 512
	},
	'db-perf-optimized-N-80': {
		'vCPUs': 80,
		'Memory': 640
	},
	'db-perf-optimized-N-96': {
		'vCPUs': 96,
		'Memory': 768
	},
	'db-perf-optimized-N-128': {
		'vCPUs': 128,
		'Memory': 864
	},
}

export function separateLastLine(input: string): { mainText: string, lastLine: string } {
	// Split the input into lines
	const lines = input.trim().split('\n');

	// Get the last line
	const lastLine = lines.pop()?.trim() || '';

	// Join the remaining lines to form the main text
	const mainText = lines.join('\n').trim();

	return { mainText, lastLine };
}

export const bwIconsMapping: Record<string, ReactElement> = {
	'compute#disk': <VolumeBWIcon height='23px' width='23px' />,
	'compute#instance': <ComputeBWIcon height='23px' width='23px' />,
	'sql#instance': <DatabaseBWIcon height='23px' width='23px' />,
	'gke#cluster': <ClusterBWIcon height='23px' width='23px' />,
	'storage#log': <LogBWIcon height='23px' width='23px' />,
};
