import './ComputeProgressBars.scss';
import { classNames, db_specs } from 'common/helpers';
import { IDBResource, INormalizedSaving, IVMResource } from 'common/interfaces';
import { ProgressBar } from 'components/ProgressBar/ProgressBar';

function ComputeProgressBars({ resource, className, saving }: { resource: IVMResource, className?: string, saving?: INormalizedSaving | null }) {
    return <div data-cyid='cy-compute-progress-bars-component' className={classNames('compute-progress-bars-component', className)}>
        <ProgressBar
            max={(resource as IVMResource).normalizedObject.cpu_size}
            value={null}
            color='#4285F4'
            title='CPU'
            percentageValue={parseFloat((resource as IVMResource)?.normalizedObject?.cpu_utilization?.toFixed(2)) || 0}
        />
        <ProgressBar
            max={(resource as IVMResource).normalizedObject.memory_size}
            value={null}
            color='#5ED0F8'
            title='MEM'
            percentageValue={parseFloat((resource as IVMResource)?.normalizedObject?.memory_utilization?.toFixed(2)) || 0}
        />
    </div>
};

function DBProgressBars({ resource, className, saving }: { resource: IDBResource, className?: string, saving: INormalizedSaving | null }) {
    function extractIntegerFromString(input: string): number {
        const regex = /(?:db-custom|pd-standard)?-(\d+)-\d+/;
        const match = input.match(regex);

        if (match && match[1]) {
            return parseInt(match[1], 10);
        }

        // If input doesn't match the regex, try to find it in specs
        const vCPUs = db_specs[input]?.vCPUs;

        if (vCPUs !== undefined) {
            return vCPUs;
        }

        return 1; // default value if no match is found
    }
    return <div data-cyid='cy-compute-progress-bars-component' className={classNames('compute-progress-bars-component', className)}>
        <ProgressBar
            max={extractIntegerFromString((resource as IDBResource).normalizedObject.type)}
            value={(resource as IDBResource).normalizedObject.cpu_utilization}
            color='#4285F4'
            title='CPU'
            percentageValue={null}
        />

    </div>
};

export { ComputeProgressBars, DBProgressBars };
