import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { IReportsData } from 'common/interfaces';

const styles = StyleSheet.create({
	mainContainer: {
		border: '1px solid #CBD5E0',
		padding: '20px 16px',
		borderRadius: 12,
		marginTop: 20,
		flexDirection: 'column',
		gap: 10,
	},
	title: {
		color: '#000000',
		fontFamily: 'Inter',
		fontWeight: 700,
		fontSize: 12,
		textTransform: 'capitalize',
	},
	optimisedSavingContailer: {
		backgroundColor: 'rgba(194, 245, 219, 0.5)',
		border: '1px solid #A0EBC7',
		borderRadius: 6,
		flexDirection: 'row',
		justifyContent: 'space-between',
		height: 70,
		padding: '12px 18px',
	},
	unoptimisedSavingContailer: {
		backgroundColor: 'rgba(227, 236, 255, 0.3)',
		border: '1px solid #D9E5FF',
		borderRadius: 6,
		flexDirection: 'row',
		justifyContent: 'space-between',
		padding: '12px 18px',
		height: 70,
	},
	dataContainer: {
		flexDirection: 'column',
		gap: '5px',
	},
	dataTitle: {
		color: '#000000',
		textTransform: 'uppercase',
		fontSize: 8,
		fontWeight: 300,
	},
	datasubTitle: { color: '#000000', fontSize: 24, fontWeight: 700 },
	datasubTitlesubText: { color: '#626262', fontSize: 16, fontWeight: 400 },
});

const PricingDetails = ({ data }: { data: IReportsData }) => (
	<View style={styles.mainContainer}>
		<View>
			<Text style={styles.title}>Savings with EazyOps</Text>
			<View style={styles.optimisedSavingContailer}>
				<View style={styles.dataContainer}>
					<Text style={styles.dataTitle}>You can save</Text>
					<Text style={styles.datasubTitle}>
						{Math.floor(data.savings_percentage)}%
					</Text>
				</View>
				<View style={styles.dataContainer}>
					<Text style={styles.dataTitle}>
						{data.timeline === 'half-yearly'
							? '6 Months saving'
							: 'Monthly saving'}
					</Text>
					<Text style={styles.datasubTitle}>
						${Math.floor(data.monthly_saving)}
						<Text style={styles.datasubTitlesubText}>/mo</Text>
					</Text>
				</View>
				<View style={styles.dataContainer}>
					<Text style={styles.dataTitle}>Annual Saving</Text>
					<Text style={styles.datasubTitle}>
						${Math.floor(data.annual_saving)}
						<Text style={styles.datasubTitlesubText}>/y</Text>
					</Text>
				</View>
			</View>
		</View>
		<View>
			<Text style={styles.title}>Your current bill</Text>
			<View style={styles.unoptimisedSavingContailer}>
				<View style={styles.dataContainer}>
					<Text style={styles.dataTitle}>
						{data.timeline === 'half-yearly'
							? '6 Months spend'
							: 'Monthly Spend'}
					</Text>
					<Text style={styles.datasubTitle}>
						${Math.floor(data.monthly_spend)}
						<Text style={styles.datasubTitlesubText}>/mo</Text>
					</Text>
				</View>
				<View style={styles.dataContainer}>
					<Text style={styles.dataTitle}>Yearly Spend</Text>
					<Text style={styles.datasubTitle}>
						~${Math.floor(data.annual_spend)}
						<Text style={styles.datasubTitlesubText}>/y</Text>
					</Text>
				</View>
			</View>
		</View>
	</View>
);

export default PricingDetails;
