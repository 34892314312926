import { useCallback } from 'react'
import { styleStringWithBold } from 'common/helpers';
import { IDiscoveryOverview, IDiscoveryProject } from 'common/interfaces';

const useDiscoveryTooltipContent = ({ account, discovery }: { account: IDiscoveryProject, discovery: IDiscoveryOverview }) => {
    const getTooltipContent = useCallback(() => {
        if (discovery && account) {
            const text = `This provides an overview of your ${account?.name} account within the ${discovery?.name} Organization along with its Cost Breakdown and Recommended Actions to help reduce your cloud spending for this particular account.`;
            return styleStringWithBold(text, [account?.name, discovery?.name]);
        }
        else return;
    }, [discovery, account]);

    return { getTooltipContent }
}

export default useDiscoveryTooltipContent;