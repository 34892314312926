import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import Arrow from 'assets/reportAssets/arrowDown.png';
import { IReportsData } from 'common/interfaces';
import { useMemo } from 'react';

const styles = StyleSheet.create({
	row: {
		flexDirection: 'row',
		alignItems: 'center',
	},
	rowItem: {
		padding: '10px 10px',
		color: '#000000',
		fontFamily: 'Inter',
		fontWeight: 700,
		fontSize: 10,
		width: 100,
	},
	sumRows: {
		border: '1px solid #CBD5E0',
		backgroundColor: 'rgba(227, 236, 255, 0.8)',
	},
	savingRows: {
		width: '135px',
		backgroundColor: 'rgba(194, 245, 219, 0.5)',
		border: '1px solid #A0EBC7',
		flexDirection: 'row',
		alignItems: 'center',
		gap: '4px',
	},
	arrowStyles: {
		width: 7,
		marginLeft: 2,
	},
	savingPercentage: { color: '#1EB564' },
});

const ReportTableFooter = ({ data }: { data: IReportsData }) => {
	const { monthly_saving, monthly_spend, annual_saving, annual_spend } = data;

	const memoizedData = useMemo(() => {
		const monthlySavingPercentage =
			monthly_spend > 0 ? (monthly_saving / monthly_spend) * 100 : 0;
		const annualSavingPercentage =
			annual_spend > 0 ? (annual_saving / annual_spend) * 100 : 0;

		return {
			monthlySavingPercentage,
			annualSavingPercentage,
		};
	}, [annual_saving, annual_spend, monthly_saving, monthly_spend]);

	return (
		<View style={styles.row}>
			<Text style={{ ...styles.rowItem, width: '150px' }}>Total Savings</Text>
			<Text style={[styles.rowItem, styles.sumRows]}>
				${Math.floor(data.monthly_spend)} /m
			</Text>
			<View style={[styles.rowItem, styles.sumRows, styles.savingRows]}>
				<Text>${Math.floor(data.monthly_saving)} /m</Text>
				<Image src={Arrow} style={styles.arrowStyles} />
				<Text style={styles.savingPercentage}>
					{Math.floor(memoizedData.monthlySavingPercentage)}%
				</Text>
			</View>
			<Text style={[styles.rowItem, styles.sumRows]}>
				${Math.floor(data.annual_spend)} /y
			</Text>
			<View style={[styles.rowItem, styles.sumRows, styles.savingRows]}>
				<Text>${Math.floor(data.annual_saving)}/y</Text>
				<Image src={Arrow} style={styles.arrowStyles} />
				<Text style={styles.savingPercentage}>
					{Math.floor(memoizedData.annualSavingPercentage)}%
				</Text>
			</View>
		</View>
	);
};

export default ReportTableFooter;
