import { Fragment } from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import Arrow from 'assets/reportAssets/arrowDown.png';
import Cluster from 'assets/reportAssets/Cluster.png';
import Volumes from 'assets/reportAssets/Volume.png';
import Computes from 'assets/reportAssets/Compute.png';
import Database from 'assets/reportAssets/Databases.png';
import Logs from 'assets/reportAssets/Logs.png';
import { IReportResource } from 'common/interfaces';

const styles = StyleSheet.create({
	row: {
		flexDirection: 'row',
		borderBottomColor: '#CBD5E0',
		borderBottomWidth: 1,
		alignItems: 'center',
	},
	rowItem: {
		padding: '10px 10px',
		color: '#000000',
		fontFamily: 'Inter',
		fontWeight: 400,
		fontSize: 10,
		width: 100,
	},

	savingRows: {
		backgroundColor: 'rgba(194, 245, 219, 0.5)',
		border: '1px solid #A0EBC7',
		borderBottom: 'none',
		width: '135px',
		flexDirection: 'row',
		alignItems: 'center',
		gap: '4px',
	},
	arrowStyles: {
		width: 7,
		marginLeft: 2,
	},
	savingPercentage: { color: '#1EB564' },
	flexRow: {
		padding: '10px 10px',
		flexDirection: 'row',
		gap: '5px',
		alignItems: 'center',
		width: '150px',
	},
	resourceIcon: { width: '13.75px', height: '10.75px', objectFit: 'contain' },
});

const iconMapping: Record<string, string> = {
	clusters: Cluster,
	instances: Computes,
	disks: Volumes,
	databases: Database,
	logs: Logs,
	disk_snapshots: Volumes,
	database_snapshots: Database,
	static_ip: Computes,
};

const labelMapping: Record<string, string> = {
	disks: 'Volume',
	instances: 'Compute',
	databases: 'Database',
	clusters: 'Cluster',
	logs: 'Log',
	pods: 'Pod',
	deployments: 'Deployment',
	disk_snapshots: 'Volume Snapshots',
	static_ip: 'Unused IPs',
	database_snapshots: 'DB Snapshots',
};
const ReportTableRow = ({ items }: any) => {
	const rows = Object.keys(items).map((key: string, index: number) => {
		const item: IReportResource = items[key];
		const IconComponent = iconMapping[key];
		const imageSrc = IconComponent ? IconComponent : Logs;
		const monthlySavingPercentage =
			item.monthly_cost > 0
				? parseFloat(
					((item.monthly_saving / item.monthly_cost) * 100).toFixed()
				)
				: 0;
		const annualSavingPercentage =
			item.annual_cost > 0
				? parseFloat(((item.annual_saving / item.annual_cost) * 100).toFixed())
				: 0;

		return (
			<View style={styles.row} key={key} wrap >
				<View style={{ ...styles.flexRow }}>
					<Image style={styles.resourceIcon} src={imageSrc} />
					<Text style={{ ...styles.rowItem, padding: 0 }}>
						{labelMapping[key]}
					</Text>
				</View>
				<Text style={styles.rowItem}>${Math.floor(item.monthly_cost)}</Text>
				<View style={[styles.rowItem, styles.savingRows]}>
					<Text> ${Math.floor(item.monthly_saving)}</Text>
					<Image src={Arrow} style={styles.arrowStyles} />
					<Text style={styles.savingPercentage}>
						{monthlySavingPercentage}%
					</Text>
				</View>
				<Text style={styles.rowItem}>${Math.floor(item.annual_cost)}</Text>
				<View style={[styles.rowItem, styles.savingRows]}>
					<Text> ${Math.floor(item.annual_saving)}</Text>
					<Image src={Arrow} style={styles.arrowStyles} />
					<Text style={styles.savingPercentage}>{annualSavingPercentage}%</Text>
				</View>
			</View>
		);
	});
	return <Fragment>{rows}</Fragment>;
};

export default ReportTableRow;
