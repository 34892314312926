import {
	ChangeEvent,
	MouseEventHandler,
	ReactNode,
	SyntheticEvent,
} from 'react';
import { Button } from 'components/Button/Button';
import { TextInput } from 'components/TextInput/TextInput';
import { ReactComponent as FiltersIcon } from 'assets/svgs/filters.svg';
import './TableFilterHeader.scss';
import { Checkbox, FormControlLabel } from '@mui/material';
import { CustomizedTooltip } from 'components/Tooltip/Tooltip';
import { ReactComponent as TagIcon } from 'assets/svgs/tag.svg';
import ColumnDropdown from 'components/ColumnsDropdown/ColumnsDropdown';
import { ITableColumn } from 'components/Table/Table';
import ReportGenerator from 'components/ReportGenerator/ReportGenerator';
import { useSelector } from 'react-redux';
import { selectIsSingleProjectState } from 'store/discovery/discovery-selectors';

const session = sessionStorage.getItem('session');
const session_data = session ? JSON.parse(session) : null;

interface ITableFilterProps {
	setIsFilterExpanded?: React.Dispatch<React.SetStateAction<boolean>> | null;
	isExpanded?: boolean;
	handleReportSelection?: ((opt: { name: string; id: string }) => void) | null;
	searchValue: string;
	setSearchValue: React.Dispatch<React.SetStateAction<string>>;
	selectedRows?: Set<string> | null;
	handleSelectAll?:
	| ((event: SyntheticEvent<Element, Event>, checked: boolean) => void)
	| undefined;
	resourcesLength?: number | null;
	getApplyTagsTooltipContent?: (() => ReactNode) | null;
	onPopupOpen?: MouseEventHandler | undefined;
	columns?: ITableColumn<unknown | any>[];
	toggleColumnVisibility?: (columnName: string) => void;
	visibleColumns?: string[];
	searchPlaceholder?: string | undefined;
	getAccessToken?: () => void | null;
	selectedReport?: { name: string; id: string } | null;
	setSelectedReport?:
	| React.Dispatch<React.SetStateAction<{ name: string; id: string }>>
	| undefined;
	type?: string;
	id?: string;
}
const TableFilterHeader = ({
	setIsFilterExpanded,
	isExpanded,
	selectedReport = null,
	setSelectedReport,
	type,
	id,
	searchValue,
	setSearchValue,
	handleSelectAll = undefined,
	selectedRows = null,
	resourcesLength = null,
	getApplyTagsTooltipContent = null,
	onPopupOpen = undefined,
	columns,
	toggleColumnVisibility = () => { },
	visibleColumns,
	searchPlaceholder = 'Search...',
	getAccessToken,
}: ITableFilterProps) => {
	const isSingleProject = useSelector(selectIsSingleProjectState);

	return (
		<div
			data-cyid='cy-filter-component-content-header'
			className='component-content-header'
		>
			<TextInput
				id='search'
				value={searchValue}
				onChange={(e: ChangeEvent<HTMLInputElement>) =>
					setSearchValue(e.target.value as string)
				}
				type='search'
				data_cyid='cy-accounts-overview-search-text-input'
				placeholder={searchPlaceholder}
			/>
			<div className='component-content-header__filter-section'>
				{handleSelectAll && (
					<FormControlLabel
						className='select_all'
						control={<Checkbox />}
						label='Select all'
						onChange={handleSelectAll}
						checked={selectedRows?.size === resourcesLength}
					/>
				)}
				{getApplyTagsTooltipContent && (
					<div className='component-content-header__apply-tag-component'>
						<Button
							text='Apply tags'
							className='apply-tags'
							icon={<TagIcon color='#454746' />}
							disabled={!selectedRows?.size}
							onClick={onPopupOpen}
							data_cyid='cy-execute-apply-tags-action-button'
						/>
						<CustomizedTooltip
							tooltipContent={<span>{getApplyTagsTooltipContent()}</span>}
							placement='bottom-end'
							data_cyid='cy-tooltip-apply-tags-account-actions-overview'
						/>
					</div>
				)}
				<div className='component-content-header__report-dropdown'>
					{selectedReport && (
						<ReportGenerator
							type={type || ''}
							selectedReport={selectedReport}
							setSelectedReport={setSelectedReport}
							id={id || ''}
							title={`Download & Save ${type} Report`}
						/>
					)}
				</div>

				{columns && (
					<div className='account-actions-overview-component-content-header__customise-columns'>
						<ColumnDropdown
							visibleColumns={visibleColumns}
							columns={columns}
							onToggleColumnVisibility={toggleColumnVisibility}
							data_cyid='cy-account-actions-resources-columns-options'
						/>
					</div>
				)}
				{setIsFilterExpanded && (
					<Button
						text='Filters'
						icon={<FiltersIcon />}
						className='filter-button'
						small
						onClick={() => setIsFilterExpanded(() => !isExpanded)}
						data_cyid='cy-execute-filters-action-button'
					/>
				)}
				{getAccessToken && !isSingleProject && !session_data && (
					<Button
						text='Add Project'
						className='filter-button add-project'
						small
						onClick={getAccessToken}
						data_cyid='cy-execute-filters-action-button'
					/>
				)}
			</div>
		</div>
	);
};

export default TableFilterHeader;
