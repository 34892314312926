import { useCallback, useState } from 'react';
import { Dropdown } from 'components/Dropdown/Dropdown';
import { SystemUpdateAlt } from '@mui/icons-material';
import './ReportGenerator.scss';
import Report from 'components/Reports/Report';
import { IReportsData } from 'common/interfaces';
import { pdf } from '@react-pdf/renderer';
import { ReportingService } from 'api/services/report.service';
import { jsonToCSV } from 'react-papaparse';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'store/app/app-selectors';
import { getResourceType, labelMapping } from 'common/helpers';
import { selectClusterReportData } from 'store/discovery/discovery-selectors';

interface IReportGeneratorProps {
	selectedReport: { name: string; id: string } | null;
	setSelectedReport:
	| React.Dispatch<React.SetStateAction<{ name: string; id: string }>>
	| undefined;
	type: string;
	id: string;
	title: string;
	isGKE?: boolean;
}
const ReportGenerator = ({
	selectedReport,
	setSelectedReport,
	type = 'organisation',
	id,
	title,
	isGKE = false,
}: IReportGeneratorProps) => {
	const currentUser = useSelector(selectCurrentUser);
	const clusterReportData = useSelector(selectClusterReportData);
	const [reportData, setReportData] = useState<IReportsData | null>(null);
	const [isLoading, setLoading] = useState(false);

	/**
	 * Handles the selection of a report.
	 *
	 * @param opt - The selected report option containing an id and name.
	 */
	const handleReportSelection = (opt: { id: string; name: string }) => {
		if (setSelectedReport) {
			setSelectedReport(opt);
			getReports(opt);
		}
	};

	/**
	 * Calculates the half-yearly values based on the provided monthly values.
	 *
	 * @param monthlyValues - The object containing the monthly values.
	 * @returns The object containing the calculated half-yearly values.
	 */
	const calculateHalfYearlyValues = (monthlyValues: any) => {
		const halfYearlyValues: any = {
			monthly_saving: monthlyValues.monthly_saving * 6,
			annual_saving: monthlyValues.annual_saving,
			monthly_cost: monthlyValues.monthly_cost * 6,
			annual_cost: monthlyValues.annual_cost,
			YR_1_cud_saving: monthlyValues.YR_1_cud_saving.toFixed(2),
			YR_3_cud_saving: monthlyValues.YR_3_cud_saving.toFixed(2),
			spot_price: monthlyValues.spot_price.toFixed(2),
		};
		return halfYearlyValues;
	};

	/**
	 * Retrieves reports based on the provided options.
	 * @param {Object} opt - The options for retrieving the reports.
	 * @param {string} opt.id - The ID of the report.
	 * @param {string} opt.name - The name of the report.
	 * @returns {void}
	 */
	const getReports = useCallback(
		(opt: { id: string; name: string }): void => {
			setLoading(true);
			if (id) {
				handleSaveReport(opt);
				const reportType = type.includes('project') ? 'project' : type;
				ReportingService.getOrgReport(reportType, id as string)
					.then(({ data }: { data: IReportsData }) => {
						if (opt.id.includes('halfYearly')) {
							const halfYearlyOverallValues = {
								monthly_saving: data.monthly_saving * 6,
								annual_saving: data.annual_saving,
								monthly_spend: data.monthly_spend * 6,
								annual_spend: data.annual_spend,
								...(type === 'project_cuds' && {
									YR_1_cud_saving: data.YR_1_cud_saving.toFixed(2),
									YR_3_cud_saving: data.YR_3_cud_saving.toFixed(2),
									spot_price: data.spot_price.toFixed(2),
								}),
							};

							let newArray;
							// Iterate over resources and update monthly values to half-yearly
							if (data.resources && Object.keys(data.resources).length > 0) {
								const updatedResources: any = {};
								Object.keys(data.resources).forEach((resourceKey) => {
									updatedResources[resourceKey] = calculateHalfYearlyValues(
										data.resources[resourceKey]
									);
								});
								// Create a new array with updated half-yearly values
								const resultArray: any = {
									...data,
									monthly_saving: halfYearlyOverallValues.monthly_saving,
									annual_saving: halfYearlyOverallValues.annual_saving,
									monthly_spend: halfYearlyOverallValues.monthly_spend,
									annual_spend: halfYearlyOverallValues.annual_spend,
									resources: updatedResources,
									timeline: 'half-yearly',
								};

								newArray = resultArray;
							} else {
								const resultArray: any = {
									...data,
									monthly_saving: halfYearlyOverallValues.monthly_saving,
									annual_saving: halfYearlyOverallValues.annual_saving,
									monthly_spend: halfYearlyOverallValues.monthly_spend,
									annual_spend: halfYearlyOverallValues.annual_spend,
									timeline: 'half-yearly',
								};

								newArray = resultArray;
							}
							setReportData(newArray);
							if (opt.id.includes('csv')) {
								handleDownloadCSV(newArray, opt);
							} else {
								handlePDFDownload(newArray);
							}
						} else {
							if (opt.id.includes('csv')) {
								handleDownloadCSV(data, opt);
							} else handlePDFDownload(data);
							setReportData(data);
						}

					}).then(() => {
						setLoading(false);
					}).catch(() => { });
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[id, type]
	);

	/**
	 * Handles the download of a report.
	 *
	 * @param id - The ID of the report to download.
	 * @returns A Promise that resolves to the downloaded report or rejects with an error.
	 */
	const handleDownloadReport = (id: string) => {
		ReportingService.createDownloadReport(id)
			.then((res) => {
				return res;
			})
			.catch((err) => {
				return err;
			});
	};

	/**
	 * Handles saving the report.
	 * @param {any} opt - The options for saving the report.
	 */
	const handleSaveReport = useCallback(
		(opt: any) => {
			if (id) {
				const filters = { type: type };
				const body = {
					id: id,
					type: type.includes('project') ? 'project' : type,
					name: opt.id.includes('halfYearly')
						? 'Bi-Annual Savings'
						: 'Monthly Savings',
					filters: JSON.stringify(filters),
				};
				ReportingService.createReport(body)
					.then((res) => {
						return handleDownloadReport(res.data.id);
					})
					.catch((err) => {
						return err;
					});
			}
		},
		[id, type]
	);

	/**
	 * Handles the PDF download for the given data.
	 *
	 * @param data - The data used to generate the PDF.
	 */
	const handlePDFDownload = async (data: IReportsData) => {
		// Get the stored data from localStorage
		const clustertData = localStorage.getItem('cluster_reportData');

		// Check if the data exists and is valid JSON
		let clusterReport = null;
		if (clustertData) {
			try {
				clusterReport = JSON.parse(clustertData);
			} catch (error) {
				console.error('Error parsing cluster_reportData from localStorage:', error);
			}
		}

		// Use the parsed data or fallback values
		const blob = await pdf(
			<Report data={data || reportData} clusterData={clusterReportData || clusterReport} user={currentUser} type={type} />
		).toBlob();

		// Create a link element and trigger download
		const link = document.createElement('a');
		link.href = URL.createObjectURL(blob);
		link.download = `${data.account_name ?? data.resource_name}_${data.timeline}.pdf`;
		link.click();
	};


	/**
	 * Handles the download of a CSV file based on the provided data and options.
	 *
	 * @param data - The reports data.
	 * @param opt - The options for the download.
	 */
	const handleDownloadCSV = (
		data: IReportsData,
		opt: { id: string; name: string }
	) => {
		const flattenedData = [];
		const columnSums: number[] = [];

		if (type !== 'resource') {
			for (const key in data.resources) {
				const categoryData = data.resources[key];
				const labelKey = key.includes('snapshots') || key.includes('static') ? key : key.replace(/s$/, '');
				const row = [
					labelMapping[labelKey],
					categoryData.monthly_cost.toFixed(2),
					...(type !== 'project_cuds'
						? [categoryData.monthly_saving.toFixed(2)]
						: []),
					categoryData.annual_cost.toFixed(2),
					...(type !== 'project_cuds'
						? [categoryData.annual_saving.toFixed(2)]
						: []),
					...(type === 'project_cuds'
						? [
							typeof categoryData?.YR_1_cud_saving === 'string'
								? parseFloat(categoryData?.YR_1_cud_saving as string).toFixed(
									2
								) || 0
								: (categoryData?.YR_1_cud_saving as number).toFixed(2) || 0,
							typeof categoryData?.YR_3_cud_saving === 'string'
								? parseFloat(categoryData?.YR_3_cud_saving as string).toFixed(
									2
								) || 0
								: (categoryData?.YR_3_cud_saving as number).toFixed(2) || 0,
						]
						: []),
				];

				flattenedData.push(row);
			}
		} else {
			const row = [
				getResourceType(data.resource_type) ??
				labelMapping[data.resource_type.replace(/s$/, '')],
				data.monthly_spend.toFixed(2),
				data.monthly_saving.toFixed(2),
				data.annual_spend.toFixed(2),
				data.annual_saving.toFixed(2),
			];
			flattenedData.push(row);
		}

		for (let i = 1; i < flattenedData[0].length; i++) {
			columnSums[i] = 0;
		}

		flattenedData.forEach((row) => {
			for (let i = 1; i < row.length; i++) {
				columnSums[i] += parseFloat(row[i] as string);
			}
		});

		const sumRow = ['Total'].concat(
			columnSums.slice(1).map((sum) => sum.toFixed(2))
		);

		flattenedData.push(sumRow);
		const fields = [
			'Resource Type',
			opt.id.includes('halfYearly') ? 'Bi-Annual Cost' : 'Monthly Cost',
			...(type !== 'project_cuds'
				? opt.id.includes('halfYearly')
					? ['Bi-Annual Savings']
					: ['Monthly Savings']
				: []),
			'Annual Cost ',
			...(type !== 'project_cuds' ? ['Annual Savings'] : []),
			...(type === 'project_cuds' ? ['1 Year CUD', '3 Year CUD'] : []),
		];

		const csv = jsonToCSV({
			fields: fields,
			data: flattenedData,
		});
		const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
		const url = URL.createObjectURL(blob);

		const a = document.createElement('a');
		a.href = url;
		a.download = `${data.account_name ?? data.resource_name}_${data.timeline
			}.csv`;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		URL.revokeObjectURL(url);
	};

	return (
		<div>
			<Dropdown
				className='report-dropdown'
				dropdownPopoverClassName='report-dropdown-popover'
				options={[
					{
						id: 'monthly',
						name: 'Monthly PDF Report',
					},
					{ id: 'halfYearly', name: 'Bi-Annual PDF Report' },
					...(!isGKE ? [
						{ id: 'monthly_csv', name: 'Monthly CSV Report' },
						{ id: 'halfYearly_csv', name: 'Bi-Annual CSV Report' }
					] : []),
				]}
				onOptionSelected={(opt) => handleReportSelection(opt)}
				selected={selectedReport}
				data_cyid='cy-report-criteria-selection'
				placeholder={
					<div className='placeholder'>
						{title}
						<SystemUpdateAlt />
					</div>
				}
				isLoading={isLoading}
			/>
		</div>
	);
};

export default ReportGenerator;
