import { Page, Document, StyleSheet, Font, View } from '@react-pdf/renderer';
import ReportTitle from './ReportTitle';
import PricingDetails from './PricingDetails';
import AccountDetail from './AccountDetail';
import ReportingSummaryTable from './ReportingSummaryTable';
import Header from './Header';
import InterRegular from 'assets/fonts/Inter-Regular.ttf';
import InterBold from 'assets/fonts/Inter-Bold.ttf';
import InterItalic from 'assets/fonts/Inter-Italic.ttf';
import Footer from './Footer';
import { IReportsData, IUser } from 'common/interfaces';
import ProjectTable from './ProjectsData/ProjectTable';
import ResourceDetail from './ResourceReport/ResourceDetail';
import ResourceActions from './ResourceReport/ResourceActions';
import ReportingCudsTable from './ReportingCudsTable/ReportingCudsTable';
import CUDSDetails from './ReportingCudsTable/CUDsDetail';
import ClusterOverview from './ClusterReport/ClusterOverview';
import { ClusterPodsDataTable } from './ClusterReport/ClusterPodsDataTable';
import { ClusterNodesDataTable } from './ClusterReport/ClusterNodeDataTable';
import { ClusterNamespacesDataTable } from './ClusterReport/ClusterNamespacesDataTable';
import { ClusterNodePoolsDataTable } from './ClusterReport/ClusterNodePoolsDataTable';

Font.register({ family: 'Inter', src: InterRegular, fontWeight: 400 });
Font.register({ family: 'Inter', src: InterBold, fontWeight: 700 });
Font.register({
	family: 'Inter',
	src: InterItalic,
	fontWeight: 400,
	fontStyle: 'italic',
});

const styles = StyleSheet.create({
	page: {
		fontFamily: 'Inter',
		fontSize: 11,
		paddingTop: 30,
		lineHeight: 1.5,
		flexDirection: 'column',
		paddingBottom: 65,
		paddingHorizontal: 35,
	},
	body: {
		fontFamily: 'Inter',
		flexGrow: 1,
	},
	content: {
		paddingTop: 20,
		flexGrow: 1,
	},

	adjustableContent: {
		marginBottom: 40,
	},
});

const Report = ({
	data,
	user,
	type,
	clusterData,
}: {
	data: IReportsData;
	user: IUser | null;
	type: string;
	clusterData?: any;
}) => {
	return (
		<Document>
			<Page size='A4' style={styles.page}>
				<Header user={user} />
				<View style={styles.body} wrap  >
					<View style={styles.content}>
						<ReportTitle
							title='EazyOps Cost Savings Analysis'
							subTitle='Uncover Your Potential Savings with Our Platform'
						/>
						{type === 'resource' ? (
							<ResourceDetail data={data} />
						) : (
							type !== 'project_cuds' && <AccountDetail type={type} data={data} />
						)}
						{type === 'project_cuds' && <CUDSDetails data={data} />}
						{type !== 'project_cuds' && <PricingDetails data={data} />}
						{type === 'resource' && (
							<ResourceActions data={data} />
						)}
						{type === 'resource' && data?.resource_type === 'gke#cluster' && (
							<ClusterOverview data={clusterData} />
						)}

						{type === 'resource' && data?.resource_type === 'gke#cluster' && clusterData?.top_nodes?.cpu_requested?.length && (
							<ClusterNodesDataTable data={clusterData?.top_nodes?.cpu_requested} title='Top nodes - CPU Requested' />
						)}
						{type === 'resource' && data?.resource_type === 'gke#cluster' && clusterData?.top_nodes?.memory_requested?.length && (
							<ClusterNodesDataTable data={clusterData?.top_nodes?.memory_requested} title='Top nodes - Memory Requested' />
						)}
						{type === 'resource' && data?.resource_type === 'gke#cluster' && clusterData?.top_pods?.cpu_requested?.length && (
							<ClusterPodsDataTable data={clusterData?.top_pods?.cpu_requested} title='Top pods - CPU Requested' />
						)}
						{type === 'resource' && data?.resource_type === 'gke#cluster' && clusterData?.top_pods?.memory_requested?.length && (
							<ClusterPodsDataTable data={clusterData?.top_pods?.memory_requested} title='Top pods - Memory Requested' />
						)}
						{type === 'resource' && data?.resource_type === 'gke#cluster' && clusterData?.top_namespaces?.cpu_requested?.length && (
							<ClusterNamespacesDataTable data={clusterData?.top_namespaces?.cpu_requested} title='Top namespaces - CPU Requested' />
						)}
						{type === 'resource' && data?.resource_type === 'gke#cluster' && clusterData?.top_namespaces?.memory_requested?.length && (
							<ClusterNamespacesDataTable data={clusterData?.top_namespaces?.memory_requested} title='Top namespaces - Memory Requested' />
						)}
						{type === 'resource' && data?.resource_type === 'gke#cluster' && clusterData?.node_pools?.length && (
							<ClusterNodePoolsDataTable data={clusterData?.node_pools} title='Node Pools' />
						)}
						{type !== 'resource' && type === 'organisation' && (
							<ProjectTable data={data} />
						)}
						{type !== 'resource' &&
							type !== 'project_cuds' &&
							(type === 'organisation' ? (
								<View break>
									<ReportingSummaryTable data={data} />
								</View>
							) : (
								<ReportingSummaryTable data={data} />
							))}
						{type === 'project_cuds' && <ReportingCudsTable data={data} />}
					</View>
				</View>
				<Footer />
			</Page>
		</Document>
	);
};

export default Report;
