import { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { TOKEN_KEY } from 'common/constants';
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';
import { AppDispatch } from 'store';
import { getCurrentUserInfo, userSignOut } from 'store/app/app-thunks';
import { CoreRoutes } from 'routing/routing-paths';
import { getCookie, setCookie } from 'common/helpers';
import { jwtDecode } from 'jwt-decode';
import { NotificationsListProvider } from 'components/Notifications/NotificationsList/NotificationsListContext';
import { useSearchParams } from 'react-router-dom';
import { useAuthSync } from 'hooks/useAuthSync';
interface AuthGuardProps {
	children: ReactElement;
}

const AuthGuard = ({ children }: AuthGuardProps) => {
	const dispatch = useDispatch<AppDispatch>();
	const location = useLocation();
	const [searchParams] = useSearchParams();

	const { broadcastAuthStatus } = useAuthSync();
	const [authStatus, setAuthStatus] = useState({
		isChecked: false,
		isAllowed: false,
	});

	const checkCallbackURL = () => {
		if (searchParams.get('tenant_id')) {
			const currentPath = location.pathname + location.search;
			setCookie('redirect', currentPath, 1);
		}
	}

	const checkAuth = async () => {
		let isChecked = false;
		let isAllowed = false;

		const token = getCookie(TOKEN_KEY);

		if (token) {
			const decodedToken = jwtDecode<{ exp: number }>(token);

			if (decodedToken && decodedToken.exp) {
				const expirationDate = new Date(decodedToken.exp * 1000);
				const currentDate = new Date();

				if (expirationDate < currentDate) {
					checkCallbackURL();
					await dispatch(userSignOut());
					broadcastAuthStatus(false);
					isChecked = true;
					isAllowed = false;
				} else {
					try {
						await dispatch(getCurrentUserInfo(token));
						broadcastAuthStatus(false);
						isChecked = true;
						isAllowed = true;
					} catch (error) {
						checkCallbackURL();
						await dispatch(userSignOut());
						broadcastAuthStatus(false);
						isChecked = true;
						isAllowed = false;
					}
				}
			} else {
				checkCallbackURL();
				await dispatch(userSignOut());
				broadcastAuthStatus(false);
				isChecked = true;
				isAllowed = false;
			}
		} else {
			checkCallbackURL();
			await dispatch(userSignOut());
			broadcastAuthStatus(false);
			isChecked = true;
			isAllowed = false;
		}

		setAuthStatus({ isChecked, isAllowed });
	};

	useEffect(() => {
		checkAuth();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const result = authStatus.isAllowed ? (
		<NotificationsListProvider>{children}</NotificationsListProvider>
	) : (
		<Navigate to={CoreRoutes.SIGN_IN}></Navigate>
	);

	return <>{authStatus.isChecked ? result : <LoadingSpinner />}</>;
};

export default AuthGuard;
