import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import './Tabs.scss';
interface TabData {
	label: string | React.ReactNode;
	content: React.ReactNode;
	onClick?: () => void;
	isDisabled?: boolean;
	href?: string;
}

interface DynamicTabsProps {
	tabs: TabData[];
	content?: React.ReactElement | null;
	selectedTab: number;
	setSelectedTab: (tab: number) => void;
	scrollToPosition?: () => void | null;
}

function DynamicTabs({
	tabs,
	content,
	selectedTab,
	setSelectedTab,
	scrollToPosition,
}: DynamicTabsProps) {
	const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		setSelectedTab(newValue);
		scrollToPosition && scrollToPosition();
	};

	return (
		<>
			<Tabs
				value={selectedTab}
				onChange={handleChange}
				variant='scrollable'
				scrollButtons='auto'
				className='tabs-component'
				data-cyid='cy-dynamic-tabs-component'
			>
				{tabs?.map((tab, index) => (
					<Tab
						key={index}
						label={tab.label}
						disabled={tab?.isDisabled}
						onClick={tab?.onClick}
						className='tab'
					/>
				))}
				{content}
			</Tabs>
			{tabs.map((tab, index) => (
				<TabPanel key={index} value={selectedTab} index={index}>
					{tab.content}
				</TabPanel>
			))}
		</>
	);
}

const TabPanel = React.memo(function TabPanel(props: {
	value: number;
	index: number;
	children: React.ReactNode;
}) {
	const { value, index, children } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`tabpanel-${index}`}
			aria-labelledby={`tab-${index}`}
			className='tabs-tab-panel'
			data-cyid='cy-tabs-tab-panel'
		>
			{value === index && (
				<div className='tabs-tab-panel-parent'>
					<div className='tabs-tab-panel-content'>{children}</div>
				</div>
			)}
		</div>
	);
});

export { DynamicTabs };
