// src/guards/QueryParamGuard.tsx
import { useAuthSync } from 'hooks/useAuthSync';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, Navigate } from 'react-router-dom';
import { selectCurrentUser } from 'store/app/app-selectors';

interface QueryParamGuardProps {
    paramName: string;
    redirectTo: string;
    children: React.ReactNode;
}

const QueryParamGuard: React.FC<QueryParamGuardProps> = ({
    paramName,
    redirectTo,
    children,
}) => {
    const selectedUser = useSelector(selectCurrentUser);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const actualParamValue = searchParams.get(paramName);
    const grantArray = actualParamValue ? actualParamValue.split(',').map(value => value.trim()) : [];

    useAuthSync();
    // Guard against missing query params
    if (!actualParamValue || grantArray.length === 0) {
        console.warn('Invalid or missing parameter value');
        return <>{children}</>;
    }

    const tenant_id = searchParams.get('tenant_id');

    // Only navigate if the tenant_id is present and the grantArray contains the user email
    if (tenant_id && !grantArray.includes(selectedUser?.email as string)) {
        return <Navigate to={redirectTo} replace />;
    }

    return <>{children}</>;
};


export default QueryParamGuard;
