import { useEffect, useRef, useState } from 'react';
import { CopyAll } from '@mui/icons-material';
import Prism from 'prismjs';
import 'prismjs/components/prism-yaml';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-hcl';
import 'prismjs/themes/prism.css';
import './CopyContainer.scss';
import { IconButton } from '@mui/material';

interface ClipBoardProps {
    text: string;
    language?: 'powershell' | 'bash' | 'yaml' | 'hcl' | 'shell';
    className?: string;
    icon?: React.ReactNode | null;
    mainClassName?: string;
}

const ClipBoard = ({ text, language, className, mainClassName, icon }: ClipBoardProps) => {
    const codeRef = useRef<HTMLPreElement>(null);
    const [isCopied, setCopied] = useState(false);
    const [showCopiedText, setShowCopiedText] = useState(false);

    useEffect(() => {
        Prism.highlightAll();
    }, [text, language]);

    const handleCopyClick = () => {
        if (codeRef.current) {
            navigator.clipboard
                .writeText(codeRef.current.textContent || '')
                .then(() => {
                    setCopied(true);
                    setShowCopiedText(true);

                    setTimeout(() => {
                        setShowCopiedText(false);
                    }, 3000);
                })
                .catch((error) => {
                    console.error('Unable to copy text to clipboard.', error);
                });
        }
    };

    return (
        <div className={`content__copy-container ${mainClassName || ''}`} data-cyid='cy-copy-content-container'>
            <div className={`content__copy-container__copy ${icon ? 'with-icon' : ''}`}>
                {icon}
                <div className='copy-button'>
                    <IconButton onClick={handleCopyClick} className='small blue' title='Copy'>
                        <CopyAll />
                    </IconButton>
                    {isCopied && (
                        <span className={`copy-feedback ${showCopiedText ? 'fade-in' : 'fade-out'}`}>
                            Copied!
                        </span>
                    )}
                </div>
            </div>
            <pre ref={codeRef} className={className || 'content__copy-container__code'}>
                <code className={`language-${language}`}>
                    {text}
                </code>
            </pre>
        </div>
    );
};

export default ClipBoard;
