import './ConfirmPopupContent.scss';
import { ReactElement } from 'react';
import { ReactComponent as ConfirmIcon } from 'assets/svgs/confirm.svg';

function ConfirmPopupContent({ children, title }: { children: ReactElement, title?: string }) {
    return <div data-cyid='cy-confirm-popup-content' className='confirm-popup-content-component'>
        <h1 className='confirm-popup-content-component-title'>
            <ConfirmIcon className='confirm-popup-content-component-icon' />{title || 'Confirmation'}
        </h1>
        <div className='confirm-popup-content-component-content'>
            {children}
        </div>
    </div>
}

export { ConfirmPopupContent }