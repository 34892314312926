import React from 'react';
import { ReactComponent as UnauthorizedAccessSVG } from 'assets/svgs/unauthorised.svg'
import './UnauthorisedAccess.scss';
import { Link } from 'react-router-dom';

const UnauthorizedAccess: React.FC = () => {
    sessionStorage.setItem('unAuthorized', 'true');
    return (
        <div className='unauthorized-component'>
            <UnauthorizedAccessSVG height='600px' width='600px' />
            <Link to='/' className='back-link'>Go Back to Home Page</Link>
        </div>
    );
};

export default UnauthorizedAccess;
