import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IUser } from 'common/interfaces';
import { getCurrentUserInfo } from './app-thunks';
import { userSignOut } from './app-thunks';
interface AppState {
  currentUser: IUser | null;
  isUserAuthenticated: boolean;
  currentUserCapabilities: string[];
  pathname: string;
}

const initialState: AppState = {
  currentUser: null,
  isUserAuthenticated: false,
  currentUserCapabilities: [],
  pathname: window.location.pathname,
};


const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setPathname: (state, action: PayloadAction<string>) => {
      state.pathname = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getCurrentUserInfo.fulfilled, (state, { payload }) => {
        return {
          ...state,
          currentUser: payload.user,
          capabilities: payload.capabilities,
          isUserAuthenticated: true
        };
      })
      .addCase(userSignOut.fulfilled, () => initialState)
  }
});

export const { setPathname } = appSlice.actions;
export const appReducer = appSlice.reducer;