import { createAsyncThunk } from '@reduxjs/toolkit';
import { UsersService } from 'api/services/users.service';
import { TOKEN_KEY } from 'common/constants';
import { deleteCookie } from 'common/helpers';

export const getCurrentUserInfo = createAsyncThunk('app.getCurrentUserInfo', async (token: string, { dispatch, rejectWithValue }) => {
    try {
        const { data } = await UsersService.me(token);
        return { user: data, capabilities: [] };
    } catch (err: any) {
        dispatch({ type: 'RESET_STATE' });
        return rejectWithValue(err.response.data);
    }
});
export const userSignOut = createAsyncThunk('app.userSignOut', async (_, { dispatch }) => {
    deleteCookie(TOKEN_KEY);
    localStorage.clear();
    dispatch({ type: 'RESET_STATE' });
});