import './DateDropdown.scss';
import { Popover } from '@mui/material';
import { DateSelection } from 'components/DateSelection/DateSelection';
import { MouseEvent, useState } from 'react';
import { ReactComponent as CalendarIcon } from 'assets/svgs/calendar-new.svg';

function DateDropdown({
	title,
	onDateSelected, placeholder
}: {
	onDateSelected: (date: string | null) => void;
	title: string;
	placeholder?: string;
}) {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	const onSelect = (date: string | null) => {
		setAnchorEl(null);
		onDateSelected(date);
	};

	const onOpenDropdown = (e: MouseEvent<HTMLSpanElement>) => {
		e.stopPropagation();
		setAnchorEl(e.currentTarget);
	};

	return (
		<>
			<div
				data-cyid='cy-date-dropdown-component'
				className='date-dropdown-component'
			>
				<span className='date-dropdown-component-title'>{title}</span>
				<button
					type='button'
					onClick={onOpenDropdown}
					data-cyid='cy-calendar-dropdown-button'
					title={placeholder}
				>
					<CalendarIcon />
				</button>
			</div>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={() => setAnchorEl(null)}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
				onClick={(e) => e.stopPropagation()}
				data-cyid='cy-date-dropdown-popover'
			>
				<DateSelection onApply={onSelect} />
			</Popover>
		</>
	);
}

export { DateDropdown };
