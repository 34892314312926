import './DateSelection.scss';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { classNames } from 'common/helpers';
import { Button } from 'components/Button/Button';
import { Dropdown } from 'components/Dropdown/Dropdown';
import { useState } from 'react';

const hours = Array.from({ length: 12 }, (value, index) => ({
	id: `${index}`,
	name: `${index < 9 ? 0 : ''}${index}`,
}));

const minutes = Array.from({ length: 60 }, (value, index) => ({
	id: `${index}`,
	name: `${index < 10 ? 0 : ''}${index}`,
}));

function DateSelection({
	onApply,
}: {
	onApply: (date: string | null) => void;
}) {
	const [timePeriod, setTimePeriod] = useState<'am' | 'pm'>('am');
	const [selectedDate, setSelectedDate] = useState<string | null>(null);
	const [selectedHours, setSelectedHours] = useState<string | null>(null);
	const [selectedMinutes, setSelectedMinutes] = useState<string | null>(null);

	const onApplyClick = () => {
		if (selectedDate) {
			const date = new Date(selectedDate);
			const hours = selectedHours ? +selectedHours : 0;
			const normalizedHours = timePeriod === 'am' ? hours : hours + 12;
			const minutes = selectedMinutes ? +selectedMinutes : 0;
			date.setHours(normalizedHours, minutes);
			onApply(date.toISOString());
		}
	};

	return (
		<div data-cyid='cy-calendar-component' className='calendar-component'>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<DateCalendar
					showDaysOutsideCurrentMonth
					onChange={(newValue: string | null) => setSelectedDate(newValue)}
				/>
			</LocalizationProvider>
			<div className='time' data-cyid='cy-calendar-time'>
				<div>Time</div>
				<div className='time-value'>
					<div className='time-inputs'>
						<Dropdown
							options={hours}
							placeholder='hh'
							className='input'
							onOptionSelected={(hours) => setSelectedHours(hours.id)}
							data_cyid='cy-dropdown-hh-input'
						/>
						<Dropdown
							options={minutes}
							placeholder='mm'
							className='input'
							onOptionSelected={(minutes) => setSelectedMinutes(minutes.id)}
							data_cyid='cy-dropdown-mm-input'
						/>
					</div>
					<div className='buttons'>
						<button
							type='button'
							onClick={() => setTimePeriod('am')}
							className={classNames(
								'button',
								timePeriod === 'am' && 'selected'
							)}
							data-cyid='cy-am-time-period-button'
						>
							am
						</button>
						<button
							type='button'
							onClick={() => setTimePeriod('pm')}
							className={classNames(
								'button',
								timePeriod === 'pm' && 'selected'
							)}
							data-cyid='cy-pm-time-period-button'
						>
							pm
						</button>
					</div>
				</div>
			</div>
			<div className='buttons'>
				<Button
					className='blue'
					text='Apply'
					small
					onClick={onApplyClick}
					disabled={!selectedDate}
					data_cyid='cy-execute-date-selection-button'
				/>
			</div>
		</div>
	);
}

export { DateSelection };
