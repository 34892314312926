import './ApplyProjections.scss';
import { classNames, styleStringWithBold, toFixed } from 'common/helpers';
import { Button } from 'components/Button/Button';
import { Dropdown } from 'components/Dropdown/Dropdown';
import { useCallback, useMemo, useState } from 'react';
import {
	IDBResource,
	IDiscoveryOverview,
	IDiscoveryProject,
	IGKEResource,
	INormalizedSaving,
	IVMResource,
	IVolumeResource,
	ResourceActionType,
	ResourceType,
} from 'common/interfaces';
import { CustomizedTooltip } from 'components/Tooltip/Tooltip';
import { VMActionsDetails } from 'components/ActionDetails/VMActionDetails';
import { VolumeActionsDetails } from 'components/ActionDetails/VolumeActionDetails';
import { DBActionsDetails } from 'components/ActionDetails/DBActionDetails';
import { GKEActionsDetails } from 'components/ActionDetails/GKEActionDetails';
import { useLocation } from 'react-router-dom';
import { Skeleton } from '@mui/material';

interface ProjectionsProps {
	savingPercentage: number | null;
	potentialCost: number | null;
	actualCost: number | null;
	loading?: boolean;
	slotContent?: { title: string; value: string };
	className?: string;
	showSavingSelection?: boolean;
	savingsOptions?: Record<ResourceActionType, INormalizedSaving[]> | INormalizedSaving[] | undefined;
	isApplyLoading?: boolean;
	resource?: IVolumeResource | IVMResource | IDBResource | IGKEResource | null;
	account?: IDiscoveryProject | null;
	discovery?: IDiscoveryOverview | null;
	isAccountsOverview?: boolean;
	resourceType?: ResourceType;
	isGKEResourcePage?: boolean;
	data_cyid: string;
	isLoading?: boolean;
}

function ApplyProjections({
	slotContent,
	savingPercentage,
	potentialCost,
	actualCost,
	loading = false,
	className,
	showSavingSelection = true,
	savingsOptions,
	resource,
	resourceType,
	account,
	discovery,
	isAccountsOverview,
	isGKEResourcePage = false,
	data_cyid = 'cy-apply-projections',
	isLoading = false
}: ProjectionsProps) {
	const location = useLocation();

	const [selectedSaving, setSelectedSaving] =
		useState<INormalizedSaving | null>(() => {
			if (!location.pathname.includes('gke')) {
				if (location.pathname.includes('delete')) {
					return (savingsOptions as Record<ResourceActionType, INormalizedSaving[]>)?.delete[0] || null;
				}
				else {
					return (savingsOptions as Record<ResourceActionType, INormalizedSaving[]>)?.scaling[0] || null;
				}
			} else {
				return (savingsOptions as INormalizedSaving[])?.length ? (savingsOptions as INormalizedSaving[])[0] : null
			}
		});


	const savingDropdownOptions = useMemo(() => {
		if (!location.pathname.includes('gke')) {
			if (location.pathname.includes('delete')) {
				return (savingsOptions as Record<ResourceActionType, INormalizedSaving[]>)?.delete;
			} else {
				return (savingsOptions as Record<ResourceActionType, INormalizedSaving[]>)?.scaling;
			}
		} else return savingsOptions as INormalizedSaving[];
	}, [location.pathname, savingsOptions]);

	const [isActionDetailsOpen, setIsActionDetailsOpen] = useState(false);

	const actualCostInfo: number | null =
		actualCost === null
			? selectedSaving?.detail.cost_before || null
			: actualCost;
	const potentialCostInfo: number | null =
		potentialCost === null
			? selectedSaving?.detail.cost_after || null
			: potentialCost;
	const savingPercentageInfo: number | null =
		savingPercentage === null
			? selectedSaving?.detail.saving_percentage || null
			: savingPercentage;

	const getTooltipContent = (text: string, boldArr: string[]) => {
		return styleStringWithBold(text, boldArr);
	};
	const getResourceActionDetails = useCallback(() => {
		if (selectedSaving && resource)
			switch (resourceType) {
				case ResourceType.VOLUME:
					return (
						<VolumeActionsDetails
							open={true}
							onClose={() => setIsActionDetailsOpen(false)}
							saving={selectedSaving}
							resource={resource as IVolumeResource}
							account={account || undefined}
						/>
					);
				case ResourceType.COMPUTE:
					return (
						<VMActionsDetails
							open={true}
							onClose={() => setIsActionDetailsOpen(false)}
							saving={selectedSaving}
							resource={resource as IVMResource}
							account={account || undefined}
						/>
					);
				case ResourceType.DATABASE:
					return (
						<DBActionsDetails
							open={true}
							onClose={() => setIsActionDetailsOpen(false)}
							saving={selectedSaving}
							resource={resource as IDBResource}
							account={account || undefined}
						/>
					);
				case ResourceType.GKE:
					return (
						<GKEActionsDetails
							open={true}
							onClose={() => setIsActionDetailsOpen(false)}
							saving={selectedSaving}
							resource={resource as IGKEResource}
							account={account || undefined}
						/>
					);
				default:
					return null;
			}
	}, [resource, account, resourceType, selectedSaving]);

	return (
		<div
			className={classNames(
				'apply-projections-component-advance',
				loading && 'ghost',
				className
			)}
			data-cyid={data_cyid}
			style={{ maxWidth: isGKEResourcePage ? '80%' : '100%', overflow: 'auto' }}
		>
			{!loading && (
				<div
					data-cyid='cy-apply-projections-component-advance-info'
					className='apply-projections-component-advance-info'
				>
					<div
						data-cyid='cy-apply-projections-component-advance-info-stats'
						className='apply-projections-component-advance-info-stats'
					>
						{slotContent && (
							<div
								data-cyid='cy-apply-projections-component-advance-info-stats-item'
								className='apply-projections-component-advance-stats-item'
							>
								<div className='apply-projections-component-advance-stats-item-value blue'>
									{slotContent.value}
								</div>
								<div className='apply-projections-component-advance-stats-item-text'>
									{slotContent.title}
								</div>
							</div>
						)}
						<div
							data-cyid='cy-apply-projections-component-advance-info-stats-item-actual-cost'
							className='apply-projections-component-advance-stats-item'
						>
							{isLoading ? <Skeleton variant="rectangular" width='150px' /> : <div className='apply-projections-component-advance-stats-item-value red'>
								{actualCostInfo !== null
									? `$${toFixed(actualCostInfo, 2)}`
									: 0}
							</div>}
							<div className='apply-projections-component-advance-stats-item-text'>
								<span className='projections-label'>
									<span>Actual Cost</span>
									<CustomizedTooltip
										tooltipContent={
											isAccountsOverview
												? getTooltipContent(
													`This is your Current Actual Cost you are paying for your ${discovery?.name} Organization`,
													['Current Actual Cost', discovery?.name || '']
												)
												: getTooltipContent(
													`This is your current actual cost that you are paying for your ${account?.name} Account within the ${discovery?.name} Organization`,
													[
														'current actual cost',
														account?.name || '',
														discovery?.name || '',
													]
												)
										}
										placement='bottom-end'
										data_cyid='cy-tooltip-apply-projection-actual-cost'
									/>
								</span>
							</div>
						</div>
						<div
							data-cyid='cy-apply-projections-component-advance-info-stats-item-potential-cost'
							className='apply-projections-component-advance-stats-item'
						>
							{isLoading ? <Skeleton variant="rectangular" width='150px' /> : <div className='apply-projections-component-advance-stats-item-value green'>
								{potentialCostInfo !== null
									? `$${toFixed(potentialCostInfo, 2)}`
									: 0}
							</div>}
							<div className='apply-projections-component-advance-stats-item-text bold'>
								<span className='projections-label'>
									<span>Potential Cost</span>
									<CustomizedTooltip
										tooltipContent={
											isAccountsOverview
												? getTooltipContent(
													`This is estimated cost after applying actions. Based on our system’s intelligence we have identified you can bring 
                                    down your Actual Cloud Cost.`,
													[
														'estimated cost',
														'applying actions',
														'Actual Cloud Cost',
													]
												)
												: getTooltipContent(
													`This is estimated cost after applying actions. Based on our system’s intelligence we have identified you can bring 
                                    down your Actual Cloud Cost for the ${account?.name} Account within the ${discovery?.name} Organization.`,
													[
														'estimated cost',
														'applying actions',
														'Actual Cloud Cost',
														account?.name || '',
														discovery?.name || '',
													]
												)
										}
										placement='bottom-end'
										data_cyid='cy-tooltip-apply-projection-potential-saving'
									/>
								</span>
							</div>
						</div>
						<div
							data-cyid='cy-apply-projections-component-advance-info-stats-item-saving-percentage'
							className='apply-projections-component-advance-stats-item'
						>
							{isLoading ? <Skeleton variant="rectangular" width='150px' /> : <div className='apply-projections-component-advance-stats-item-value green'>
								{savingPercentageInfo !== null
									? `${toFixed(savingPercentageInfo, 2)}%`
									: savingPercentageInfo ?? 0}
							</div>}
							<div className='apply-projections-component-advance-stats-item-text bold'>
								<span className='projections-label'>
									<span>Savings Percentage</span>
									<CustomizedTooltip
										tooltipContent={
											isAccountsOverview
												? getTooltipContent(
													`We can bring your savings from 0% to ${discovery?.savings_percentage}%  if you choose to apply the actions based on our recommendations.`,
													[
														`0 % to ${discovery?.savings_percentage}%`,
														'apply the actions',
														'recommendations',
													]
												)
												: getTooltipContent(
													`We can bring your savings from 0% to ${discovery?.savings_percentage}% for the ${account?.name} Account within the ${discovery?.name} Organization if you choose to apply the actions based on our recommendations.`,
													[
														`0% to ${discovery?.savings_percentage}%`,
														account?.name || '',
														discovery?.name || '',
														'apply the actions',
														'our recommendations',
													]
												)
										}
										placement='bottom-end'
										data_cyid='cy-tooltip-apply-projection-saving-percentage'
									/>
								</span>
							</div>
						</div>
					</div>
					{showSavingSelection && (
						<div
							data-cyid='cy-apply-projections-component-advance-stats-item-saving-criteria'
							className='apply-projections-component-advance-info-savings'
						>
							<div
								data-cyid='cy-apply-projections-component-advance-stats-item-saving-criteria-dropdown'
								className='apply-projections-component-advance-stats-item criteria-dropdown'
							>
								<div className='apply-projections-component-advance-stats-item-text'>
									Saving criteria
								</div>
								<Dropdown
									options={savingDropdownOptions || []}
									placeholder='Nothing selected'
									selected={selectedSaving}
									onOptionSelected={setSelectedSaving}
									data_cyid='cy-dropdown-apply-projections-saving-selections'
								/>
							</div>
							<Button
								className='blue'
								small
								text='Review Saving'
								onClick={() => setIsActionDetailsOpen(true)}
								disabled={!selectedSaving}
								data_cyid='cy-apply-projection-open-action-button'
							/>
						</div>
					)}
				</div>
			)}
			{isActionDetailsOpen &&
				selectedSaving &&
				resource &&
				getResourceActionDetails()}
		</div>
	);
}

export { ApplyProjections };
