import { useState } from 'react';
import './ActionDetails.scss';
import { Drawer } from '@mui/material';
import { ReactComponent as PlusIcon } from 'assets/svgs/plus.svg';
import {
	IDiscoveryProject,
	IGKEResource,
	INormalizedSaving,
} from 'common/interfaces';
import {
	classNames,
	getCostValue,
	showApplyResourceActionNotification,
} from 'common/helpers';
import { Button } from 'components/Button/Button';
import { ProjectionsService } from 'api/services/projections.service';
import { useNotifications } from 'components/Notifications/useNotifications';
import { ReactComponent as ArrowLowIcon } from 'assets/svgs/arrow-low.svg';
import { ReactComponent as GKEActionIcon } from 'assets/svgs/scaleGKE.svg';
import { Tile } from 'components/Tile/Tile';
import { Popup } from 'components/Popup/Popup';
import { ConfirmPopupContent } from 'components/Popup/ConfirmPopupContent/ConfirmPopupContent';
import { LoadingProgress } from 'components/LoadingProgress/LoadingProgress';
import { useNavigate, useParams } from 'react-router';
import { DateDropdown } from 'components/DateDropdown/DateDropdown';
import { ReactComponent as ScaleGKE } from 'assets/svgs/GKEProduct.svg';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'store/app/app-selectors';
import SubscriptionPopup from 'features/Payment/SubscriptionPopup/SubscriptionPopup';

const session = sessionStorage.getItem('session');
const session_data = session ? JSON.parse(session) : null;

function GKEResourceImpact({
	resource,
	state,
	saving,
}: {
	resource: IGKEResource;
	state: 'before' | 'after';
	saving: INormalizedSaving;
}) {
	return (
		<ul
			data-cyid='cy-actions-compute-resource-details'
			className={classNames(state)}
		>
			<li className='action-details-component-info-item-li list-item'>
				{state === 'before' ? resource?.normalizedObject?.node_count : 'NA'}
			</li>
			<li className='action-details-component-info-item-li list-item'>
				{state === 'before' ? resource?.normalizedObject?.node_count : 'NA'}
			</li>
			<li className='action-details-component-info-item-li list-item'>
				{state === 'before' ? 'NA' : 'NA'}
			</li>
			<li className='action-details-component-info-item-li list-item'>
				{state === 'before' ? 'NA' : 'NA'}
			</li>
		</ul>
	);
}

function GKECostImpact({ saving }: { saving: INormalizedSaving }) {
	return (
		<>
			<div
				data-cyid='cy-volume-cost-details'
				className='action-details-component-info-item-block titles'
			>
				<h3>Cost Impact</h3>
				<div className='action-details-component-info-item-li'>Total Cost:</div>
				<div className='action-details-component-info-item-li'>
					Total Saving:
				</div>
			</div>
			<div
				data-cyid='cy-volume-cost-current-details'
				className='action-details-component-info-item-block current'
			>
				<div className='action-details-component-info-item-block-title'>
					Current State
				</div>
				<div className='action-details-component-info-item-li list-item'>
					{getCostValue(saving?.detail.cost_before)}
				</div>
			</div>
			<div
				data-cyid='cy-gke-cost-after-details'
				className='action-details-component-info-item-block after'
			>
				<div className='action-details-component-info-item-block-title'>
					After Recommended Action
				</div>
				<div className='action-details-component-info-item-li list-item'>
					{getCostValue(saving?.detail.cost_after)}
				</div>
				<div className='action-details-component-info-item-li list-item'>
					<span className='diff'>
						<ArrowLowIcon className='icon' />
						{getCostValue(
							saving?.detail.cost_before - saving?.detail.cost_after
						)}
					</span>
				</div>
			</div>
		</>
	);
}

function KeyActions({ saving }: { saving: INormalizedSaving }) {
	return (
		<>
			<h3>Key actions</h3>
			{saving.action_essentails.non_disruptive && (
				<div className='action-details-component-info-item-li'>
					<span className='label'>Non-Reversible:</span>
					<span className='value'>Downtime Is not required to execute.</span>
				</div>
			)}
			{saving?.detail.action === 'scaling' && (
				<div className='action-details-component-info-item-li'>
					<span className='label'>Reversible:</span>
					<span className='value'>Action can be manually reverted</span>
				</div>
			)}
		</>
	);
}

function ResourceDetails({
	resource,
	account,
}: {
	resource: IGKEResource;
	account?: IDiscoveryProject;
}) {
	return (
		<>
			<div
				data-cyid='cy-resource-action-details'
				className='action-details-component-info-item-li resource-name'
			>
				<span className='label'>Cluster Name:</span>
				<Tile color='blue' text={resource.name} />
			</div>
			<div
				data-cyid='cy-resource-action-details-info'
				className='action-details-component-info-item-li'
			>
				<span className='label'>Account:</span>
				<span className='value account-name'>{account?.name}</span>
			</div>
			<div
				data-cyid='cy-resource-action-details-info-item'
				className='action-details-component-info-item-li'
			>
				<span className='label'>Cluster ID:</span>
				<span className='value'>{resource.id}</span>
			</div>
			<div className='action-details-component-info-item-li'>
				<span className='label'>Region:</span>
				<span className='value'>
					{resource.normalizedObject.region || 'NA'}
				</span>
			</div>
		</>
	);
}

interface ActionsDetailsProps {
	open: boolean;
	onClose: () => void;
	saving: INormalizedSaving;
	resource: IGKEResource;
	account?: IDiscoveryProject;
}

function GKEActionsDetails({
	open,
	onClose,
	saving,
	resource,
	account,
}: ActionsDetailsProps) {
	const notifications = useNotifications();
	const { discoveryId, accountId } = useParams();
	const navigate = useNavigate();
	const selectedUser = useSelector(selectCurrentUser);
	const resourceType = 'Cluster';

	const [isApplyLoading, setIsApplyLoading] = useState(false);
	const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);
	const [isOpen, setOpen] = useState(false);

	/**
	 * Handles the scheduling of a projection.
	 *
	 * @param {string | null} date - The date to schedule the projection.
	 */
	const onSchedule = (date: string | null) => {
		// TO DO changes for projection_id here (design ?)
		if (date) {
			ProjectionsService.applyProjection(
				[saving.projection_id],
				date?.slice(0, 23)
			).catch((error) => console.log({ error }));
		}
	};

	/**
	 * Handles the apply action for the GKE action details.
	 */
	const onApply = () => {
		setIsApplyLoading(true);
		ProjectionsService.applyProjection([saving.projection_id])
			.then(() => {
				setIsApplyLoading(false);
				showApplyResourceActionNotification(notifications, saving, resource);
				navigate(
					`/discovery/${discoveryId}/account/${accountId}/actions/database/resource/${resource.id}`
				);
				onClose();
			})
			.catch(() => {
				setIsApplyLoading(false);
			});
	};

	/**
	 * Executes the action when triggered.
	 * If the selected user has a renewed_on property, it sets the open state to false and opens the confirm popup.
	 * Otherwise, it sets the open state to true.
	 */
	const onExecute = () => {
		if (selectedUser?.renewed_on) {
			setOpen(false);
			setIsConfirmPopupOpen(true);
		} else {
			setOpen(true);
		}
	};

	return (
		<Drawer
			anchor='right'
			open={open}
			onClose={onClose}
			onClick={(e) => e.stopPropagation()}
			data-cyid='cy-action-details-drawer'
		>
			<div className='action-details-component'>
				<h2>
					<PlusIcon />
					Recommended Action Details
				</h2>
				<div className='action-details-component-controls'>
					<div className='action-details-component-controls-item name'>
						Recommended Action:{' '}
						<div className='action-details-component-controls-item name-detail'>
							{saving?.detail.action} {resourceType}
							<div className='action-details-component-controls-item icon'>
								<GKEActionIcon />
							</div>
						</div>
					</div>
					<div className='action-details-component-controls-item buttons'>
						<div className='action-details-component-controls-item-cost'>
							<div>
								{getCostValue(
									saving?.detail.cost_before - saving?.detail.cost_after
								)}
							</div>
							<div className='saving'>Saving</div>
						</div>
						{!session_data &&
							<>
								<Button
									small
									className='blue'
									text='Execute Action Now'
									onClick={onExecute}
									data_cyid='cy-execute-action-button'
								/>
								<DateDropdown onDateSelected={onSchedule} title='Execute Later:' placeholder='Schedule action' />
							</>
						}
					</div>
				</div>
				<div className='action-details-component-info'>
					<div className='action-details-component-info-top'>
						<div className='action-details-component-info-item details left'>
							<h3 className='resource-title_DB'>
								<div className='resource-title_head'>
									<ScaleGKE />
									GKE details
								</div>
								<span className='blue'>{account?.name}</span>
							</h3>
							<ResourceDetails resource={resource} account={account} />
						</div>
						<div className='action-details-component-info-item right'>
							<div className='action-details-component-info-item-block titles'>
								<h3>Resource Impact</h3>
								<div className='action-details-component-info-item-li'>
									<span className='label'>No. of Nodes:</span>
								</div>
								<div className='action-details-component-info-item-li'>
									<span className='label'>No. of Drain Nodes:</span>
								</div>
								<div className='action-details-component-info-item-li'>
									<span className='label'>vCPU:</span>
								</div>
								<div className='action-details-component-info-item-li'>
									<span className='label'>Memory:</span>
								</div>
							</div>
							<div className='action-details-component-info-item-block current'>
								<div className='action-details-component-info-item-block-title'>
									Current State
								</div>
								<GKEResourceImpact
									resource={resource}
									state='before'
									saving={saving}
								/>
							</div>
							<div className='action-details-component-info-item-block after'>
								<div className='action-details-component-info-item-block-title'>
									After Recommended Action
								</div>
								<GKEResourceImpact
									resource={resource}
									state='after'
									saving={saving}
								/>
							</div>
						</div>
					</div>
					<div className='action-details-component-info-bottom'>
						<div className='action-details-component-info-item left'>
							<KeyActions saving={saving} />
						</div>
						<div className='action-details-component-info-item right'>
							<GKECostImpact saving={saving} />
						</div>
					</div>
				</div>
			</div>
			{isOpen && (
				<SubscriptionPopup open={isOpen} onClose={() => setOpen(!isOpen)} />
			)}
			{isConfirmPopupOpen && (
				<Popup
					open={true}
					onClose={() => setIsConfirmPopupOpen(false)}
					submitButtonText='Yes'
					cancelButtonText='No'
					onSubmit={onApply}
					className='confirm-resource-action-popup'
					data_cyid='cy-popup-gke-action-details-modal'
				>
					<ConfirmPopupContent>
						<>
							<p>You're about to perform the following actions.</p>
							<Tile
								color='red'
								text={`${saving.detail.action} 1 ${resourceType}`}
								className='action-details-confirm-tile'
							/>
							<p>Are you sure you want to proceed with these changes?</p>
							{isApplyLoading && (
								<LoadingProgress text='Please be patient while we apply the recommended action(s)' />
							)}
						</>
					</ConfirmPopupContent>
				</Popup>
			)}
		</Drawer>
	);
}

export { GKEActionsDetails };
